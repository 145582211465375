import { Button, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import useAxios from "../../../common/auth/useAxios";
import InputText from "../../../common/components/InputText/InputText";
import CheckBox from "../../../common/components/CheckBox/CheckBox";

type CreateCompanyModalProps = {
  show: boolean;
  onHide: any;
  refresh: any;
};

const CreateCompanyBasicModal: React.FC<CreateCompanyModalProps> = ({
  show,
  onHide,
  refresh,
}) => {
  const [companyName, setCompanyName] = useState<string>("");
  const [geminiTenant, setGeminiTenant] = useState<string>("");
  const [geminiSecret, setGeminiSecret] = useState<string>("");
  const [usingGeminiLive, setUsingGeminiLive] = useState<boolean>(false);
  const { post } = useAxios();

  function handleSubmit(event: any) {
    event.preventDefault();
    event.stopPropagation();
    console.log(companyName);
    try {
      post("company", {
        name: companyName,
        UsingGeminiLive: usingGeminiLive && !(geminiTenant.length === 0),
        geminiTenant: geminiTenant,
        geminiSecret: geminiSecret,
      }).then((response: any) => {
        console.log(response);
        onHide();
        refresh();
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Opprett nytt selskap</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputText
            value={companyName}
            onChange={(e: any) => setCompanyName(e.target.value)}
            inputTitle="Selskapsnavn"
            label={true}
          />
          <Row>
            <div className="d-flex flex-row">
              <CheckBox
                checked={usingGeminiLive}
                onChange={(x: any) => {
                  setUsingGeminiLive(x.target.checked);
                }}
              />
              <p className="ms-4">Koble til Gemini Live</p>
            </div>
          </Row>
          {usingGeminiLive && (
            <div>
              <InputText
                value={geminiTenant}
                onChange={(e: any) => setGeminiTenant(e.target.value)}
                inputTitle="Gemini Tenant"
                label={true}
              />
              <InputText
                value={geminiSecret}
                onChange={(e: any) => setGeminiSecret(e.target.value)}
                inputTitle="Gemini Secret"
                label={true}
              />
              <p className="pt-2 mb-1 d-flex">
                Dersom du er usikker på verdiene nå, kan du koble til Gemini
                Live senere, under selskaps innstillinger i menyen.
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Lagre</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreateCompanyBasicModal;
