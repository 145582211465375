import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./MeasurementSelector.scss";

type ChartTypeSelectorProps = {
  bottomUpSelected: boolean;
  selectBottomUp: any;
};

const MeasurementSelector: React.FC<ChartTypeSelectorProps> = ({
  bottomUpSelected,
  selectBottomUp,
}) => {
  if (bottomUpSelected) {
    return (
      <div className="d-flex flex-row">
        <div className="unit-selected me-1">
          <i
            className="fs-3 bi bi-arrow-bar-up d-flex flex-row justify-content-center align-items-center"
            style={{ color: "green", cursor: "pointer" }}
          ></i>
        </div>

        <OverlayTrigger
          overlay={
            <Tooltip id={"tooltip-1"}>Velg måling fra enhet og ned</Tooltip>
          }
          placement="top"
          delay={100}
        >
          <div className="unit-not-selected">
            <i
              className="fs-3 bi bi-arrow-bar-down"
              style={{ color: "gray", cursor: "pointer", zIndex: 100 }}
              onClick={() => selectBottomUp(false)}
            ></i>
          </div>
        </OverlayTrigger>
      </div>
    );
  }
  return (
    <div className="d-flex flex-row">
      <OverlayTrigger
        overlay={
          <Tooltip id={"tooltip-1"}>Velg måling fra bunnen og opp</Tooltip>
        }
        placement="top"
        delay={100}
      >
        <div className="unit-not-selected me-1">
          <i
            className="fs-3 bi bi-arrow-bar-up"
            style={{ color: "gray", cursor: "pointer" }}
            onClick={() => selectBottomUp(true)}
          ></i>
        </div>
      </OverlayTrigger>
      <div className="unit-selected">
        <i
          className="fs-3 bi bi-arrow-bar-down"
          style={{ color: "green", cursor: "pointer" }}
        ></i>
      </div>
    </div>
  );
};

export default MeasurementSelector;
