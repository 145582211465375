import { createStore, createTypedHooks } from "easy-peasy";
import { AccountModel, account } from "./AccountModel";

export interface StoreModel {
  account: AccountModel;
}

export const storeModal: StoreModel = {
  account,
};
const store = createStore<StoreModel>(storeModal, {
  name: "Global VEL IOT Store",
});
export const { useStoreActions, useStoreState, useStoreDispatch } =
  createTypedHooks<StoreModel>();
export default store;
