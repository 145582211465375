import React, { useState, useEffect } from "react";
import LoginButton from "../../../common/components/LoginButton/LoginButton";
import LogoutButton from "../../../common/components/Logout/LogoutButton";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../../../common/components/Layout/PageLayout/PageLayout";
import "./LandingPage.scss";
import { Col, Row } from "react-bootstrap";
import LandingPageAuthenticated from "./LandingPageAuthenticated";
import Vel1200Section from "./Vel1200Section";

const LandingPage: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isAuthenticated) {
    return (
      <PageLayout>
        <LandingPageAuthenticated />
      </PageLayout>
    );
  }

  if (!isLoading && !isAuthenticated) {
    return (
      <PageLayout>
        <div>
          <Row className="justify-content-center mb-5">
            <div
              className="d-flex flex-row justify-content-center"
              style={{ width: 800 }}
            >
              <div className="landing-header-container">
                <h1 className="landing-header">VEL SENSOR</h1>
              </div>
              <div
                className="d-flex justify-content-left align-items-center"
                style={{ width: 200 }}
              >
                <LoginButton />
              </div>
            </div>
          </Row>
          <Row className="justify-content-center mt-5">
            <div
              className="d-flex flex-row justify-content-center mt-5"
              style={{ width: 800 }}
            >
              <Vel1200Section />
            </div>
          </Row>
        </div>
      </PageLayout>
    );
  }
  return null;
};

export default LandingPage;
