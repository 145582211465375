import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { PageLayout } from "../../common/components/Layout/PageLayout/PageLayout";
import useAxios from "../../common/auth/useAxios";
import { Button, Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import EditDeviceModal from "./EditDeviceModal";
import { useStoreState } from "../../store/store";
import {
  DropdownDeviceArea,
  DropdownProject,
} from "../../global-types/DropDownTypes";
import {
  Chart as ChartJs,
  LineElement,
  TimeScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import zoom from "chartjs-plugin-zoom";
import "chartjs-adapter-date-fns";

import { Line } from "react-chartjs-2";

import { useParams } from "react-router-dom";
import { GetDeviceDataPoint, GetDeviceDetails } from "./types/DeviceDetails";
import LabelAndText from "../../common/components/LabelAndText/LabelAndText";
import MeasurementSelector from "../../common/components/MeasurementSelector/MeasurementSelector";

ChartJs.register(
  LineElement,
  TimeScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  zoom
);

const DevicePage = () => {
  const { deviceId } = useParams<{ deviceId: string }>();
  const [showLineChart, setShowLineChart] = useState<boolean>(true);
  const [showBottomUp, setShowBottomUp] = useState<boolean>(true);
  const [showEditDeviceModal, setShowEditDeviceModal] =
    useState<boolean>(false);

  const [allProjects, setAllProjects] = useState<DropdownProject[]>([]);
  const [allDeviceAreas, setAllDeviceAreas] = useState<DropdownDeviceArea[]>(
    []
  );
  const [device, setDevice] = useState<GetDeviceDetails>();
  const company = useStoreState((state) => state.account.selectedCompany);
  const [graphStartDate, setGraphStartDate] = useState<Date>(new Date());
  const [graphEndDate, setGraphEndDate] = useState<Date>(new Date());
  const [graphData, setGraphData] = useState<any>();
  const [graphOptions, setgraphOptions] = useState<any>();
  const [graphBatteryData, setGraphBatteryData] = useState<any>();
  const [graphBatteryOptions, setgraphBatteryOptions] = useState<any>();
  const [graphSignalData, setGraphSignalData] = useState<any>();
  const [graphSignalOptions, setgraphSignalOptions] = useState<any>();
  const { get, post } = useAxios();

  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      console.log("deviceId = " + deviceId);
      getCompanyData();
      getDeviceData();
    }
  }, [isAuthenticated]);

  function getTransmissonTypeText() {
    switch (device?.transmissionType) {
      case 0:
        return "Ingen";
      case 1:
        return "Eksternt API";
      case 2:
        return "UDP";
      case 3:
        return "Gemini Live";
      default:
        return "Ukjent";
    }
  }

  useEffect(() => {
    if (device?.dataPoints && device?.dataPoints.length > 0) {
      var deviceDataPoints = device?.dataPoints;
      setDevice(device);

      const showBottomUpData =
        showBottomUp &&
        device?.distanceToBottom !== null &&
        device?.distanceToBottom > 0;

      convertDataPointsToLineData(
        deviceDataPoints,
        showBottomUpData,
        device?.distanceToBottom
      );
      convertBatteryDataPointsToLineData(deviceDataPoints);
      convertSignalDataPointsToLineData(deviceDataPoints);
    }
  }, [device, showBottomUp]);

  function openEditDeviceModal() {
    setShowEditDeviceModal(true);
  }

  async function getCompanyData() {
    try {
      await get("company/" + company?.id).then((response) => {
        const projects: DropdownProject[] = response.data.projects;
        setAllProjects(projects);
        const deviceAreas: DropdownProject[] = response.data.deviceAreas;
        setAllDeviceAreas(deviceAreas);
      });
    } catch {
      console.log("Failed to get company data");
    }
  }

  async function getDeviceData() {
    try {
      await get("device/" + deviceId).then((response) => {
        const deviceData: GetDeviceDetails = response.data;
        setDevice(deviceData);
        if (deviceData?.distanceToBottom == 0) {
          setShowBottomUp(false);
        }
      });
    } catch {
      console.log("Failed to get company data");
    }
  }

  function getUnitTypeText() {
    switch (device?.deviceTypeId) {
      case 0:
        return "Ikke valgt";
      case 1:
        return "VEL D12";
      case 2:
        return "VEL D20";
      case 3:
        return "VEL D30";
      case 5:
        return "VEL T1";
      case 6:
        return "VEL PH1";
      default:
        return "Ukjent";
    }
  }

  async function getDatePointsFromTime() {
    try {
      await post("device/datapoints", {
        deviceId: deviceId,
        fromDate: graphStartDate,
        toDate: graphEndDate,
      }).then((response) => {
        const deviceDataPoints: GetDeviceDataPoint[] = response.data.dataPoints;

        if (device != null) {
          device.dataPoints = deviceDataPoints;
          setDevice(device);

          const showBottomUpData =
            showBottomUp &&
            device?.distanceToBottom !== null &&
            device?.distanceToBottom > 0;

          convertDataPointsToLineData(
            deviceDataPoints,
            showBottomUpData,
            device?.distanceToBottom
          );
          convertBatteryDataPointsToLineData(deviceDataPoints);
          convertSignalDataPointsToLineData(deviceDataPoints);
        }
      });
    } catch {
      console.log("Failed to get company data");
    }
  }

  async function convertSignalDataPointsToLineData(
    dataPoints: GetDeviceDataPoint[]
  ) {
    let points = dataPoints.map((point) => {
      return {
        x: convertDateFormat(point.time),
        y: point.signalStrength,
        r: 5,
      };
    });

    if (points.length > 0) {
      const max = Math.max(...points.map((p) => p.y));
      updateSignalGraphOptions(32);
    }
    const newData = {
      datasets: [
        {
          label: "signalstyrke",
          data: points,
          fill: false,
          borderColor: "rgb(0, 50, 192)",
          tension: 0.1,
        },
      ],
    };
    setGraphSignalData(newData);
  }

  async function convertBatteryDataPointsToLineData(
    dataPoints: GetDeviceDataPoint[]
  ) {
    let points = dataPoints.map((point) => {
      return {
        x: convertDateFormat(point.time),
        y: point.batteryV,
        r: 5,
      };
    });

    if (points.length > 0) {
      const max = Math.max(...points.map((p) => p.y));
      updateBatteryGraphOptions(4);
    }
    const newData = {
      datasets: [
        {
          label: "mV",
          data: points,
          fill: false,
          borderColor: "rgb(75, 100, 150)",
          tension: 0.1,
        },
      ],
    };
    setGraphBatteryData(newData);
  }

  async function convertDataPointsToLineData(
    dataPoints: GetDeviceDataPoint[],
    fromBottom: boolean,
    distanceToBottom: number
  ) {
    let points = [];
    if (fromBottom) {
      points = dataPoints.map((point) => {
        return {
          x: convertDateFormat(point.time),
          y: distanceToBottom - point.distance,
          r: 5,
        };
      });
    } else {
      points = dataPoints.map((point) => {
        return {
          x: convertDateFormat(point.time),
          y: point.distance,
          r: 5,
        };
      });
    }

    if (points.length > 0) {
      const max = Math.max(...points.map((p) => p.y));
      const min = Math.min(...points.map((p) => p.y));
      updateGraphOptions(max > 0 ? max * 1.3 : 12, min < 0 ? min - 0.5 : 0);
    }
    let label = fromBottom ? "Meter" : "Avstand fra enhet (m)";
    if (device?.deviceTypeId === 5) {
      label = "Temperatur (°C)";
    }

    const newData = {
      datasets: [
        {
          label: label,
          data: points,
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
      ],
    };
    setGraphData(newData);
  }

  function convertDateFormat(dateStr: Date) {
    // Parse the original date string
    const date = new Date(dateStr);

    // Construct a new date string
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, add 1 to match calendar months
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    // Combine parts into the final format
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

    return formattedDate;
  }

  function updateGraphOptions(maxValue: number, minValue: number) {
    const tempGraphOptions = {
      scales: {
        x: {
          type: "time",
          time: {
            // Adjust display formats as needed
            tooltipFormat: "yyyy-MM-dd HH:mm",
            displayFormats: {
              quarter: "MMM yyyy",
              month: "MMM yyyy",
              day: "MMM dd",
              hour: "HH:mm",
              minute: "HH:mm",
            },
          },
          ticks: {
            // This function can be used to dynamically change the tick display based on the zoom level
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,
          },
        },
        y: {
          min: minValue,
          max: maxValue,
        },
      },
      plugins: {
        zoom: {
          limits: {
            y: { min: minValue, max: maxValue },
          },
          // pan: {
          //   enabled: true,
          //   mode: "xy",
          // },
          zoom: {
            wheel: {
              enabled: true,
            },
            // pinch: {
            //   enabled: true,
            // },
            drag: {
              enabled: true,
              mode: "xy",
            },
            mode: "xy",
          },
        },
      },
    };

    setgraphOptions(tempGraphOptions);
  }

  function updateBatteryGraphOptions(maxValue: number) {
    const tempGraphOptions = {
      scales: {
        x: {
          type: "time",
          time: {
            // Adjust display formats as needed
            tooltipFormat: "yyyy-MM-dd HH:mm",
            displayFormats: {
              quarter: "MMM yyyy",
              month: "MMM yyyy",
              day: "MMM dd",
              hour: "HH:mm",
              minute: "HH:mm",
            },
          },
          ticks: {
            // This function can be used to dynamically change the tick display based on the zoom level
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,
          },
        },
        y: {
          min: 0,
          max: maxValue,
        },
      },
      plugins: {
        zoom: {
          limits: {
            y: { min: 0, max: maxValue },
          },
          // pan: {
          //   enabled: true,
          //   mode: "xy",
          // },
          zoom: {
            wheel: {
              enabled: true,
            },
            // pinch: {
            //   enabled: true,
            // },
            drag: {
              enabled: true,
              mode: "xy",
            },
            mode: "xy",
          },
        },
      },
    };

    setgraphBatteryOptions(tempGraphOptions);
  }

  function updateSignalGraphOptions(maxValue: number) {
    const tempGraphOptions = {
      scales: {
        x: {
          type: "time",
          time: {
            // Adjust display formats as needed
            tooltipFormat: "yyyy-MM-dd HH:mm",
            displayFormats: {
              quarter: "MMM yyyy",
              month: "MMM yyyy",
              day: "MMM dd",
              hour: "HH:mm",
              minute: "HH:mm",
            },
          },
          ticks: {
            // This function can be used to dynamically change the tick display based on the zoom level
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,
          },
        },
        y: {
          min: 0,
          max: maxValue,
        },
      },
      plugins: {
        zoom: {
          limits: {
            y: { min: 0, max: maxValue },
          },
          // pan: {
          //   enabled: true,
          //   mode: "xy",
          // },
          zoom: {
            wheel: {
              enabled: true,
            },
            // pinch: {
            //   enabled: true,
            // },
            drag: {
              enabled: true,
              mode: "xy",
            },
            mode: "xy",
          },
        },
      },
    };

    setgraphSignalOptions(tempGraphOptions);
  }

  function GetMeasurementHeader() {
    if (device?.deviceTypeId === 5) {
      return "Temperaturmålinger:";
    } else {
      if (showBottomUp) {
        return "Avstand fra bunnen og opp:";
      } else {
        return "Avstand fra enhet og ned:";
      }
    }
  }

  // interface ChartProps {
  //   showBottomUp: boolean;
  //   dataPoints: LineData[];
  // }

  // const Chart = React.memo((props: ChartProps) => {
  //   return (
  //     <SmallChart
  //       data={lineData}
  //       width={600}
  //       height={700}
  //       showLineChart={showLineChart}
  //       yMaxValue={showBottomUp ? device?.distanceToBottom : maxYValueGraph}
  //       showAreaChart={showBottomUp}
  //       yLegend={showBottomUp ? "Høyde" : "Avstand"}
  //     />
  //   );
  // });

  return (
    <PageLayout>
      <Row>
        <Col xs={12} md={4} className="pe-5">
          <h3>{device?.name}</h3>
          <p>{device?.description}</p>
          <div style={{ maxWidth: 300 }}>
            <LabelAndText label="Enhetstype" text={getUnitTypeText()} />
            <LabelAndText label="Prosjekt" text={device?.projectName} />

            <LabelAndText label="Lokasjon" text={device?.deviceAreaName} />
          </div>
        </Col>

        <Col xs={12} md={4}>
          <h3>Sending av data:</h3>
          <LabelAndText
            label="Overføringstype"
            text={getTransmissonTypeText()}
          />
          {device?.transmissionType === 1 ||
            (device?.transmissionType === 2 && (
              <LabelAndText
                label="Adresse"
                text={device?.transmissionAddress}
              />
            ))}
          <h3 className="mt-4">Metadata:</h3>
          {device?.transmissionType === 1 ||
            (device?.transmissionType === 3 && (
              <LabelAndText
                label="Enhetsnavn i mottagersystem"
                text={device?.customerSystemName}
              />
            ))}
          <LabelAndText label="IOT kortnummer" text={device?.iotCardNumber} />
          <LabelAndText
            label="Bunnareal av tank/silo"
            text={device?.locationAreal?.toString()}
          />
          <LabelAndText
            label="Høyde til tank/silo"
            text={device?.locationDiameter?.toString()}
          />
        </Col>
        <Col xs={12} md={4}>
          <Row className="pt-4">
            <Button
              variant="primary"
              type="button"
              onClick={() => openEditDeviceModal()}
              className="w-50"
            >
              Endre enhet
            </Button>
          </Row>
        </Col>
      </Row>

      <div className="mt-5 pt-5 d-flex flex-row justify-content-center">
        <Col xs={12} md={8}>
          <div className="d-flex flex-row justify-content-center">
            <div>
              <h3 className="mb-3">{GetMeasurementHeader()}</h3>

              <div style={{ width: 800, height: 400 }}>
                {graphData && (
                  <Line
                    data={graphData}
                    options={graphOptions as ChartOptions<"line">}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="mt-5 d-flex flex-row justify-content-center">
            <div className="me-5">
              <h3 className="mb-3">Batteristatus (mV):</h3>

              <div style={{ width: 400, height: 300 }}>
                {graphBatteryData && (
                  <Line
                    data={graphBatteryData}
                    options={graphBatteryOptions as ChartOptions<"line">}
                  />
                )}
              </div>
            </div>

            <div>
              <h3 className="mb-3">Signalstyrke:</h3>

              <div style={{ width: 400, height: 300 }}>
                {graphSignalData && (
                  <Line
                    data={graphSignalData}
                    options={graphSignalOptions as ChartOptions<"line">}
                  />
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="ms-5">
            <MeasurementSelector
              bottomUpSelected={showBottomUp}
              selectBottomUp={(selectBottomUp: boolean) =>
                setShowBottomUp(selectBottomUp)
              }
            />
          </div>
          <div className="mt-5" style={{ maxWidth: 205 }}>
            <p className="fw-bold">Velg tidspunkt:</p>
            <Row className="pb-2">
              <span>Fra</span>
              <DatePicker
                selected={graphStartDate}
                onChange={(date: Date) => setGraphStartDate(date)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={60}
                dateFormat="dd.MM.yyyy HH:mm"
                className="ms-2 mt-1"
              />
            </Row>
            <Row>
              <span>Til</span>
              <DatePicker
                selected={graphEndDate}
                onChange={(date: Date) => setGraphEndDate(date)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={60}
                dateFormat="dd.MM.yyyy HH:mm"
                className="ms-2 mt-1"
              />
            </Row>
            <Row>
              <div className="d-flex flex-row justify-content-center mt-3">
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => getDatePointsFromTime()}
                >
                  Velg
                </Button>
              </div>
            </Row>
          </div>
        </Col>
      </div>

      {showEditDeviceModal && (
        <EditDeviceModal
          show={showEditDeviceModal}
          onHide={() => {
            setShowEditDeviceModal(false);
            getDeviceData();
          }}
          projects={allProjects}
          deviceAreas={allDeviceAreas}
          device={device}
        />
      )}
    </PageLayout>
  );
};

export default DevicePage;
