import { DeviceCardData } from "./DeviceCardTypes";
import "./DeviceCard.scss";
import { Col, Row } from "react-bootstrap";
import SmallChart, { DataPoint, LineData } from "./SmallChart";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";

type DeviceCardProps = {
  cardData: DeviceCardData;
  showLineChart?: boolean;
  showBottomUp?: boolean;
};

const DeviceCard: React.FC<DeviceCardProps> = ({
  cardData,
  showLineChart = true,
  showBottomUp = true,
}) => {
  const [lineData, setLineData] = useState<LineData[]>([]);
  const [maxYValueGraph, setMaxYValueGraph] = useState<number>(12);
  const [lastSignal, setLastSignal] = useState<number>(25);
  const [lastBattery, setLastBattery] = useState<number>(3);
  const navigate = useNavigate();
  useEffect(() => {
    if (cardData.dataPoints && cardData.dataPoints.length > 0) {
      if (
        showBottomUp &&
        cardData.distanceToBottom &&
        cardData.distanceToBottom > 0
      ) {
        const points = cardData.dataPoints.map((point) => {
          return {
            x: new Date(point.time),
            y: cardData.distanceToBottom - point.distance,
          };
        });
        var line: LineData = {
          id: cardData.name,
          data: points,
        };
        setLineData([line]);
        console.log(line);
      } else {
        const points = cardData.dataPoints.map((point) => {
          return {
            x: new Date(point.time),
            y: point.distance,
          };
        });
        var line: LineData = {
          id: cardData.name,
          data: points,
        };
        const max = Math.max(...points.map((p) => p.y));
        setMaxYValueGraph(max > 0 ? max * 1.3 : 12);
        const lastPoint = cardData.dataPoints[0];
        setLastSignal(lastPoint.signalStrength);
        setLastBattery(lastPoint.batteryV);
        setLineData([line]);
        console.log(line);
      }
    } else {
      setLineData([]);
    }
  }, [cardData, showBottomUp]);

  function handleRedirect() {
    navigate("/device-details/" + cardData.id);
  }

  interface ChartProps {
    showBottomUp: boolean;
  }

  const Chart = React.memo((props: ChartProps) => {
    return (
      <SmallChart
        data={lineData}
        width={250}
        height={250}
        showLineChart={showLineChart}
        yMaxValue={
          props.showBottomUp ? cardData.distanceToBottom : maxYValueGraph
        }
        showAreaChart={showBottomUp}
        yLegend={props.showBottomUp ? "Høyde" : "Avstand"}
      />
    );
  });

  return (
    <div className="device-card-layout" onClick={() => handleRedirect()}>
      <div className="d-flex flex-row w-100">
        {/* <div style={{ width: 36 }}></div> */}
        <span className="fs-5 d-flex flex-row flex-grow-1 text-center justify-content-center">
          <b>{cardData.name}</b>
        </span>
        {/* <div style={{ width: 36 }}>
          <i className="fs-4 bi bi-star-fill" style={{ color: "orange" }}></i>
        </div> */}
      </div>
      <div className="d-flex flex-row w-100" style={{ minHeight: 75 }}>
        <Col xs={10}>
          <div className="d-flex flex-grow flex-grow-1">
            <div>
              <p className="description-text">{cardData.description}</p>
            </div>
          </div>
          <span>
            <b>
              {cardData.projectName} - {cardData.deviceAreaName}
            </b>
          </span>
        </Col>
        <Col xs={2} className="d-flex flex-column align-items-center">
          <div style={{ width: 36 }}>
            {lastSignal < 10 ? (
              <i
                className="fs-4 bi bi-reception-2"
                style={{ color: "red" }}
              ></i>
            ) : lastSignal <= 18 ? (
              <i
                className="fs-4 bi bi-reception-3"
                style={{ color: "orange" }}
              ></i>
            ) : (
              <i
                className="fs-4 bi bi-reception-4"
                style={{ color: "green" }}
              ></i>
            )}
          </div>
          <div style={{ width: 36 }}>
            {lastBattery < 2 ? (
              <i className="fs-4 bi bi-battery" style={{ color: "red" }}></i>
            ) : lastBattery <= 2.7 ? (
              <i
                className="fs-4 bi bi-battery-half"
                style={{ color: "orange" }}
              ></i>
            ) : (
              <i
                className="fs-4 bi bi-battery-full"
                style={{ color: "green" }}
              ></i>
            )}
          </div>
        </Col>
      </div>
      <div>
        <span></span>
      </div>
      <div className="mt-2">
        {/* <SmallChart
          data={lineData}
          width={300}
          height={350}
          showLineChart={showLineChart}
          yMaxValue={showBottomUp ? cardData.distanceToBottom : maxYValueGraph}
          showAreaChart={showBottomUp}
          yLegend={showBottomUp ? "Høyde" : "Avstand"}
        /> */}
        <Chart showBottomUp={showBottomUp} />
      </div>
    </div>
  );
};

export default DeviceCard;
