import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import LoadingSpinner from "../components/Loading/LoadingSpinner";
import { useStoreState } from "../../store/store";
import OnboardingPage from "../../pages/onboarding/OnboardingPage";

interface PageAuthenticatorProps {
  component: any;
}

export const PageAuthenticator: React.FC<PageAuthenticatorProps> = ({
  component,
}) => {
  const userAccount = useStoreState((state) => state.account.user);

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        <LoadingSpinner />
      </div>
    ),
  });

  if (userAccount && !userAccount?.onboarded) {
    console.log(userAccount);
    return <OnboardingPage />;
  }

  return <Component />;
};

export default PageAuthenticator;
