import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { BreakpointProvider } from "react-socks";
import Routes from "./Routes";
import { useStoreActions } from "./store/store";
import "./App.css";
import LoadingSpinner from "./common/components/Loading/LoadingSpinner";
import useAxios from "./common/auth/useAxios";
import SidebarMenu from "./common/components/Layout/SideBar/SideBar";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import nb from "date-fns/locale/nb";
registerLocale("nb", nb);

function App() {
  const { isAuthenticated } = useAuth0();
  const { get } = useAxios();

  const setUserData = useStoreActions((state) => state.account.setUserData);
  setDefaultLocale("nb");

  const { isLoading } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      fetchCurrentUser();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  async function fetchCurrentUser() {
    const response = await get("user/current");
    setUserData(response.data);
  }

  // if (isLoading) {
  //   return <LoadingSpinner />;
  // }

  return (
    <BreakpointProvider>
      <div className="page-layout">
        <SidebarMenu />
        <div style={{ marginLeft: 230 }}>
          <Routes />
        </div>
      </div>
    </BreakpointProvider>
  );
}

export default App;
