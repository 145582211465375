import { Button, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import useAxios from "../../common/auth/useAxios";
import { AdminCompany, User } from "./types/AdminTypes";
import Dropdown from "../../common/components/DropDown/DropDown";
import CheckBox from "../../common/components/CheckBox/CheckBox";

type AddUserToCompanyModalProps = {
  show: boolean;
  onHide: any;
  companies: AdminCompany[];
  users: User[];
};

const AddUserToCompanyModal: React.FC<AddUserToCompanyModalProps> = ({
  show,
  onHide,
  companies,
  users,
}) => {
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [selectedUserId, setSelectedUserId] = useState<number>(0);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const { post } = useAxios();

  const companyItems = companies?.map((company, i) => {
    return { label: company.name, value: company.id, id: "" + i };
  });
  const userItems = users?.map((user, i) => {
    return {
      label: user.firstName + " " + user.lastName,
      value: user.id,
      id: "" + i,
    };
  });

  function handleSubmit(event: any) {
    event.preventDefault();
    event.stopPropagation();
    console.log("is Admin: " + isAdmin);
    try {
      post("company/add-user", {
        companyId: selectedCompanyId,
        userId: selectedUserId,
        isAdmin: isAdmin,
      }).then((response: any) => {
        console.log(response);
        onHide();
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Legg bruker til et selskap</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown
            items={userItems}
            onChange={(opt) => {
              setSelectedUserId(opt.value);
              setSelectedUser(opt);
              console.log(opt.value);
            }}
            placeholder="Velg bruker"
            value={selectedUser}
          />
          <Dropdown
            items={companyItems}
            onChange={(opt) => {
              setSelectedCompanyId(opt.value);
              setSelectedCompany(opt);
              console.log(opt.value);
            }}
            placeholder="Velg selskap"
            value={selectedCompany}
          />
          <Row>
            <CheckBox
              checked={isAdmin}
              onChange={(x: any) => {
                setIsAdmin(x.target.checked);
              }}
            />
            <p className="ms-4">Admin konto</p>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Lagre</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddUserToCompanyModal;
