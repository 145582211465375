import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

type InputTextProps = {
  label?: boolean;
  inputTitle: string;
  defaultValue?: any;
  value: any;
  type?: string;
  onChange: any;
  readonly?: boolean;
};

const InputText: React.FC<InputTextProps> = ({
  label = true,
  inputTitle,
  defaultValue,
  onChange,
  type = "text",
  readonly = false,
  value = "",
}) => {
  return (
    <Form.Group as={Row} className="mb-3">
      {label && <Form.Label column>{inputTitle}</Form.Label>}
      <Col sm="12">
        <Form.Control
          onChange={onChange}
          type={type}
          value={value}
          readOnly={readonly}
          defaultValue={defaultValue}
        />
      </Col>
    </Form.Group>
  );
};

export default InputText;
