import React, { useState, useEffect } from "react";

import "./LandingPage.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useStoreActions, useStoreState } from "../../../store/store";
import CreateCompanyBasicModal from "./CreateCompanyBasicModal";
import useAxios from "../../../common/auth/useAxios";
import OnboardingPage from "../../onboarding/OnboardingPage";
import { PageLayout } from "../../../common/components/Layout/PageLayout/PageLayout";
import InputText from "../../../common/components/InputText/InputText";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useNavigate } from "react-router-dom";
import CheckBox from "../../../common/components/CheckBox/CheckBox";

const LandingPageAuthenticated: React.FC = () => {
  const { user, isAuthenticated } = useAuth0();
  const [showCreateCompanyModal, setShowCreateCompanyModal] =
    useState<boolean>(false);
  const { get } = useAxios();

  const setUserData = useStoreActions((state) => state.account.setUserData);

  const userAccount = useStoreState((state) => state.account.user);

  const currentSelectedCompany = useStoreState(
    (state) => state.account.selectedCompany
  );

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [confirmDataAgreement, setConfirmDataAgreement] =
    useState<boolean>(false);
  const [confirmDeviceAgreement, setConfirmDeviceAgreement] =
    useState<boolean>(false);
  const { post } = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      if (user?.given_name !== undefined) {
        setFirstName(user?.given_name);
      }
      if (user?.family_name !== undefined) {
        setLastName(user?.family_name);
      }
      if (user?.email !== undefined) {
        setEmail(user?.email);
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (userAccount?.accessType === 9) {
      navigate("/admin");
    } else if (currentSelectedCompany !== null) {
      navigate("/dashboard");
    }
  }, [currentSelectedCompany, navigate, userAccount?.accessType]);

  function handleSubmit(event: any) {
    event.preventDefault();
    event.stopPropagation();
    console.log("" + firstName + " " + lastName);
    try {
      post("user/onboard", {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        address: "",
        address2: "",
        postCode: "",
        postArea: "",
        imageUrl: "",
        otherInfo: "",
        hasApprovedDeviceAgreement: confirmDeviceAgreement,
      }).then((response) => {
        console.log(response);
        setUserData(response.data);
        window.location.reload();
      });
    } catch (error) {
      console.log(error);
    }
  }

  function openCreateCompanyModal() {
    setShowCreateCompanyModal(true);
  }

  async function fetchCurrentUser() {
    const response = await get("user/current");
    setUserData(response.data);
  }

  if (userAccount && !userAccount?.onboarded) {
    return (
      <div>
        <h1 className="mb-5">Bruker informasjon</h1>
        <Form onSubmit={handleSubmit} style={{ maxWidth: 300 }}>
          <InputText
            value={firstName}
            onChange={(e: any) => setFirstName(e.target.value)}
            inputTitle="Fornavn"
            label={true}
          />
          <InputText
            value={lastName}
            onChange={(e: any) => setLastName(e.target.value)}
            inputTitle="Etternavn"
          />
          <InputText
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
            inputTitle="Email"
          />
          <InputText
            value={phone}
            onChange={(e: any) => setPhone(e.target.value)}
            inputTitle="Telefonnummer (valgfritt)"
          />
          <Row>
            <div className="d-flex flex-row">
              <CheckBox
                checked={confirmDeviceAgreement}
                onChange={(x: any) => {
                  setConfirmDeviceAgreement(x.target.checked);
                }}
              />
              <p className="ms-4">
                Jeg bekrefter at jeg har lest og aksepterer denne avtalen for
                <b> leie og bruk av enheter</b> fra Vastveit Elektronikk. <br />
                <Link
                  to="https://iotvelstorage.blob.core.windows.net/public/Utleie_avtale.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Åpne avtalen
                </Link>
              </p>
            </div>
          </Row>
          <Button type="submit" disabled={!confirmDeviceAgreement}>
            Lagre
          </Button>
        </Form>
      </div>
    );
  }

  if (userAccount?.onboarded && currentSelectedCompany === null) {
    return (
      <div>
        {currentSelectedCompany === null ||
        currentSelectedCompany === undefined ? ( //currentSelectedCompany
          <Row className="justify-content-center">
            <div
              className="d-flex flex-column justify-content-center align"
              style={{ width: 800 }}
            >
              <h4>Det ser ut som kontoen din ikke er knyttet et selskap. </h4>
              <p className="pt-2 mb-1">
                Ta kontakt med en administrator i selskapet ditt for å få
                tilgang.
              </p>
              <div style={{ width: 200 }}></div>
            </div>
          </Row>
        ) : null}
        {showCreateCompanyModal && (
          <CreateCompanyBasicModal
            show={showCreateCompanyModal}
            refresh={() => fetchCurrentUser()}
            onHide={() => setShowCreateCompanyModal(false)}
          />
        )}
      </div>
    );
  }

  return <div></div>;
};

export default LandingPageAuthenticated;
