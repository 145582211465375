import { useAuth0 } from "@auth0/auth0-react";
import React, { useCallback, useEffect, useState } from "react";
import { PageLayout } from "../../common/components/Layout/PageLayout/PageLayout";
import useAxios from "../../common/auth/useAxios";
import { DeviceCardData } from "../../common/components/DeviceCard/DeviceCardTypes";
import DeviceCard from "../../common/components/DeviceCard/DeviceCard";
import "./AreasPage.scss";
import Dropdown from "../../common/components/DropDown/DropDown";
import { useStoreState } from "../../store/store";
import ChartTypeSelector from "../../common/components/ChartTypeSelector/ChartTypeSelector";
import MeasurementSelector from "../../common/components/MeasurementSelector/MeasurementSelector";
import DetailsSelector from "../../common/components/DetailsSelector/DetailsSelector";
import DeviceTable from "../../common/components/DeviceTable/DeviceTable";
import { useNavigate } from "react-router-dom";
import { DeviceTableType } from "../../common/components/DeviceTable/DeviceTableType";
import { Col, Row } from "react-bootstrap";

const AreasPage = () => {
  const [allDevices, setAllDevices] = useState<DeviceCardData[]>([]);
  const [showBottomUp, setShowBottomUp] = useState<boolean>(true);
  const [allDevicesTable, setAllDevicesTable] = useState<DeviceTableType[]>([]);
  const [showGraph, setShowGraph] = useState<boolean>(true);
  const [selectedAreaId, setSelectedAreaId] = useState<number>(0);
  const [selectedArea, setSelectedArea] = useState<any>();
  const [areaItems, setAreaItems] = useState<any>([]);
  const company = useStoreState((state) => state.account.selectedCompany);
  const [showLineChart, setShowLineChart] = useState<boolean>(true);
  const { get } = useAxios();

  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  const handleClickRowDevice = useCallback(
    (data: DeviceTableType) => {
      navigate("/device-details/" + data.id);
    },
    [navigate]
  );

  useEffect(() => {
    if (isAuthenticated && company != null) {
      getAreaData();
    }
  }, [isAuthenticated, company]);

  async function getAreaData() {
    try {
      console.log("company");
      console.log(company);
      let selectedAreaId = 0;
      await get("device-area/all/" + company?.id).then((response) => {
        const areas = response.data?.deviceAreas;
        const areaItemsForDropdown = areas?.map((area: any, i: number) => {
          return { label: area.name, value: area.id, id: "" + i };
        });
        setAreaItems(areaItemsForDropdown);
        if (areaItemsForDropdown.length > 0) {
          setSelectedArea(areaItemsForDropdown[0]);
          selectedAreaId = areaItemsForDropdown[0].value;
        }
      });

      if (selectedAreaId !== 0) {
        await get("device-area/devices/" + selectedAreaId).then((response) => {
          if (response.data.devices && response.data.devices.length > 0) {
            // const test = [
            //   response.data.devices[0],
            //   response.data.devices[0],
            //   response.data.devices[0],
            //   response.data.devices[0],
            //   response.data.devices[0],
            //   response.data.devices[0],
            //   response.data.devices[0],
            //   response.data.devices[0],
            //   response.data.devices[0],
            // ];
            console.log("checking areas");
            setAllDevices(response.data.devices);
            setAllDevicesTable(response.data.devices);
            // setAllDevices(test);
          } else {
            setAllDevices([]);
          }
          //   setAllCompanies(companies);
        });
      }
    } catch {
      console.log("Failed to get admin data");
    }
  }

  async function getDevicesForArea(newAreaId: number) {
    try {
      await get("device-area/devices/" + newAreaId).then((response) => {
        if (response.data.devices && response.data.devices.length > 0) {
          setAllDevices(response.data.devices);
          setAllDevicesTable(response.data.devices);
        } else {
          setAllDevices([]);
        }
      });
    } catch {
      console.log("Failed to get admin data");
    }
  }

  return (
    <PageLayout>
      <div>
        <Row>
          <Col md={7} xs={12}>
            <div>
              <h1>Lokasjoner</h1>
              <p>
                Oversikt over alle lokasjoner, du kan bytte lokasjon i
                dropdown-menyen.
              </p>
            </div>
            <div className="d-flex flex-row">
              <div
                className="me-2 d-flex flex-row flex-grow-1"
                style={{ width: 300, maxWidth: 1150 }}
              >
                <Dropdown
                  items={areaItems}
                  onChange={(opt) => {
                    setSelectedAreaId(opt.value);
                    setSelectedArea(opt);
                    getDevicesForArea(opt.value);
                    console.log(opt.value);
                  }}
                  placeholder="Lokasjon"
                  value={selectedArea}
                />
                {/* <div className="ms-5">
              <DetailsSelector
                graphSelected={showGraph}
                selectGraph={(selectGraph: boolean) =>
                  setShowGraph(selectGraph)
                }
              />
            </div> */}
              </div>
            </div>
          </Col>
          <Col md={5} xs={12} className="d-flex mb-3">
            <div style={{ width: 200, alignSelf: "end" }}>
              <h4 className="text-center">Endre visning:</h4>
              <div className="d-flex flex-row flex-grow-1 justify-content-center mt-4">
                <DetailsSelector
                  graphSelected={showGraph}
                  selectGraph={(selectGraph: boolean) =>
                    setShowGraph(selectGraph)
                  }
                />
              </div>
              <div className="d-flex flex-row flex-grow-1 justify-content-center">
                <div className="mt-4">
                  <MeasurementSelector
                    bottomUpSelected={showBottomUp}
                    selectBottomUp={(selectBottomUp: boolean) =>
                      setShowBottomUp(selectBottomUp)
                    }
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {showGraph ? (
          <div className="mt-5 device-card-table">
            {allDevices.length > 0 &&
              allDevices.map((device) => {
                return (
                  <div className="mb-4">
                    <DeviceCard
                      cardData={device}
                      showBottomUp={showBottomUp}
                      showLineChart={showLineChart}
                    />
                  </div>
                );
              })}
            {allDevices.length === 0 && (
              <div
                className="d-flex flex-row align-items-center justify-content-center"
                style={{ minHeight: 400 }}
              >
                {/* <DeviceTable
              tableData={allDevicesTable}
              handleClickRow={handleClickRowDevice}
            /> */}
                <h2>Ingen enheter er lagt til denne lokasjonen.</h2>
              </div>
            )}
          </div>
        ) : (
          <div
            className="mt-5 d-flex flex-row align-items-center justify-content-center"
            style={{ minHeight: 400 }}
          >
            {/* <DeviceTable
              tableData={allDevicesTable}
              handleClickRow={handleClickRowDevice}
            /> */}
            <h2>Denne visningen kommer snart</h2>
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default AreasPage;
