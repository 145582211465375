import { Button, Form, Modal } from "react-bootstrap";
import InputText from "../../common/components/InputText/InputText";
import { useEffect, useState } from "react";
import useAxios from "../../common/auth/useAxios";
import {
  AdminCompany,
  AdminDeviceArea,
  AdminProject,
} from "./types/AdminTypes";
import Dropdown from "../../common/components/DropDown/DropDown";
import TextArea from "../../common/components/TextArea/TextArea";

const transmissionTypes = [
  { label: "Ingen", value: 0, id: "0" },
  { label: "Eksternt API", value: 1, id: "1" },
  { label: "UDP", value: 2, id: "2" },
  { label: "Gemini Live", value: 3, id: "3" },
];

type CreateAreaModalProps = {
  show: boolean;
  onHide: any;
  companies: AdminCompany[];
};

const CreateDeviceModal: React.FC<CreateAreaModalProps> = ({
  show,
  onHide,
  companies,
}) => {
  const [deviceName, setDeviceName] = useState<string>("");
  const [deviceIdName, setDeviceIdName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [customerSystemName, setCustomerSystemName] = useState<string>("");
  const [transmissionAddress, setTransmissionAddress] = useState<string>("");
  const [distanceToBottom, setDistanceToBottom] = useState<number>(0);
  const [password, setPassword] = useState<string>("");

  const [divisor, setDivisor] = useState<number>(1);
  const [transmissionPort, setTransmissionPort] = useState<number>(0);

  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [selectedProjectId, setSelectedProjectId] = useState<number>(0);
  const [selectedProject, setSelectedProject] = useState<any>();
  const [selectedDeviceAreaId, setSelectedDeviceAreaId] = useState<number>(0);
  const [selectedDeviceArea, setSelectedDeviceArea] = useState<any>();
  const [selectedTransmissionTypeId, setSelectedTransmissionTypeId] =
    useState<number>(0);
  const [selectedTransmissionType, setSelectedTransmissionType] =
    useState<any>();
  const [projectItems, setProjectItems] = useState<any>([]);
  const [deviceAreaItems, setDeviceAreaItems] = useState<any>([]);
  const { post } = useAxios();

  const companyItems = companies?.map((company, i) => {
    return { label: company.name, value: company.id, id: "" + i };
  });

  useEffect(() => {
    if (selectedCompanyId !== 0) {
      const company = companies.find(
        (company) => company.id === selectedCompanyId
      );
      const projects = company?.projects;
      const projectItemsForDropdown = projects?.map((project, i) => {
        return { label: project.name, value: project.id, id: "" + i };
      });
      setProjectItems(projectItemsForDropdown);
      const deviceAreas = company?.deviceAreas;
      const deviceAreaItemsForDropdown = deviceAreas?.map((deviceArea, i) => {
        return { label: deviceArea.name, value: deviceArea.id, id: "" + i };
      });
      setDeviceAreaItems(deviceAreaItemsForDropdown);
    }
  }, [selectedCompanyId, companies]);

  function handleSubmit(event: any) {
    event.preventDefault();
    event.stopPropagation();
    console.log(deviceName);
    try {
      post("device", {
        unitName: deviceName,
        name: deviceName,
        deviceId: deviceIdName,
        description,
        customerSystemName: customerSystemName,
        companyId: selectedCompanyId,
        deviceAreaId: selectedDeviceAreaId,
        projectId: selectedProjectId,
        unitDivisor: divisor,
        transmissionType: selectedTransmissionTypeId,
        transmissionAddress: transmissionAddress,
        transmissionPort: transmissionPort,
        distanceToBottom: distanceToBottom,
        password: password,
      }).then((response: any) => {
        console.log(response);
        onHide();
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Opprett ny enhet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown
            items={companyItems}
            onChange={(opt) => {
              setSelectedCompanyId(opt.value);
              setSelectedCompany(opt);
              console.log(opt.value);
            }}
            placeholder="Selskap"
            value={selectedCompany}
          />
          <Dropdown
            items={projectItems}
            onChange={(opt) => {
              setSelectedProjectId(opt.value);
              setSelectedProject(opt);
              console.log(opt.value);
            }}
            placeholder="Prosjekt"
            value={selectedProject}
          />
          <Dropdown
            items={deviceAreaItems}
            onChange={(opt) => {
              setSelectedDeviceAreaId(opt.value);
              setSelectedDeviceArea(opt);
              console.log(opt.value);
            }}
            placeholder="Lokasjon"
            value={selectedDeviceArea}
          />
          <InputText
            value={deviceName}
            onChange={(e: any) => setDeviceName(e.target.value)}
            inputTitle="Navn på enhet"
            label={true}
          />
          <InputText
            value={deviceIdName}
            onChange={(e: any) => setDeviceIdName(e.target.value)}
            inputTitle="ID til enhet"
            label={true}
          />
          <TextArea
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}
            inputTitle="Beskrivelse"
            label={true}
          />
          <InputText
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
            inputTitle="Passord"
            label={true}
          />
          <InputText
            value={distanceToBottom}
            onChange={(e: any) =>
              setDistanceToBottom(parseFloat(e.target.value))
            }
            inputTitle="Avstand til bunnen"
            label={true}
            type="number"
          />

          <InputText
            value={divisor}
            onChange={(e: any) => setDivisor(parseFloat(e.target.value))}
            inputTitle="Divisor (100 = cm til m)"
            label={true}
            type="number"
          />

          <Dropdown
            items={transmissionTypes}
            onChange={(opt) => {
              setSelectedTransmissionTypeId(opt.value);
              setSelectedTransmissionType(opt);
              console.log(opt.value);
            }}
            placeholder="Overførningstype"
            value={selectedTransmissionType}
          />
          {(selectedTransmissionTypeId === 1 ||
            selectedTransmissionTypeId === 3) && (
            <InputText
              value={customerSystemName}
              onChange={(e: any) => setCustomerSystemName(e.target.value)}
              inputTitle="Enhetsnavn i mottager system"
              label={true}
            />
          )}
          {(selectedTransmissionTypeId === 1 ||
            selectedTransmissionTypeId === 2) && (
            <InputText
              value={transmissionAddress}
              onChange={(e: any) => setTransmissionAddress(e.target.value)}
              inputTitle="Mottager adresse"
              label={true}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Lagre</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreateDeviceModal;
