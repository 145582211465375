import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

type InputTextProps = {
  label?: boolean;
  inputTitle: string;
  defaultValue?: any;
  value: any;
  onChange: any;
  width?: number;
  readonly?: boolean;
  rows?: number;
};

const TextArea: React.FC<InputTextProps> = ({
  label = true,
  inputTitle,
  defaultValue,
  onChange,
  readonly = false,
  value = "",
  rows = 3,
}) => {
  return (
    <Form.Group as={Row} className="mb-3">
      {label && (
        <Form.Label column sm="2">
          {inputTitle}
        </Form.Label>
      )}
      <Col sm="12">
        <Form.Control
          onChange={onChange}
          value={value}
          readOnly={readonly}
          defaultValue={defaultValue}
          as={"textarea"}
        />
      </Col>
    </Form.Group>
  );
};

export default TextArea;
