import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useState } from "react";
import { PageLayout } from "../../common/components/Layout/PageLayout/PageLayout";
import useAxios from "../../common/auth/useAxios";
import { Button, Col, Row } from "react-bootstrap";
import CreateCompanyModal from "./CreateCompanyModal";
import { AdminCompany, User } from "./types/AdminTypes";
import CreateAreaModal from "./CreateAreaModal";
import CreateProjectModal from "./CreateProjectModal";
import CreateDeviceModal from "./CreateDeviceModal";
import AddUserToCompanyModal from "./AddUserToCompanyModal";
import { DeviceTableType } from "../../common/components/DeviceTable/DeviceTableType";
import DeviceTable from "../../common/components/DeviceTable/DeviceTable";
import { useNavigate } from "react-router-dom";
import UserTable from "../../common/components/UserTable/UserTable";

const AdminPage = () => {
  const [showCreateCompanyModal, setShowCreateCompanyModal] =
    useState<boolean>(false);
  const [showCreateProjectModal, setShowCreateProjectModal] =
    useState<boolean>(false);
  const [showCreateDeviceAreaModal, setShowCreateDeviceAreaModal] =
    useState<boolean>(false);
  const [showCreateDeviceModal, setShowCreateDeviceModal] =
    useState<boolean>(false);
  const [showAddUserToCompany, setShowAddUserToCompany] =
    useState<boolean>(false);
  const [allCompanies, setAllCompanies] = useState<AdminCompany[]>([]);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [allDevices, setAllDevices] = useState<DeviceTableType[]>([]);
  const { get } = useAxios();

  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      getAdminData();
    }
  }, [isAuthenticated]);

  function openCreateCompanyModal() {
    setShowCreateCompanyModal(true);
  }
  function openCreateProjectModal() {
    setShowCreateProjectModal(true);
  }
  function openCreateDeviceAreaModal() {
    setShowCreateDeviceAreaModal(true);
  }
  function openCreateDeviceModal() {
    setShowCreateDeviceModal(true);
  }
  function openAddUserToCompany() {
    setShowAddUserToCompany(true);
  }

  async function getAdminData() {
    try {
      await get("admin/all-companies").then((response) => {
        const companies: AdminCompany[] = response.data.companies;
        console.log(companies);
        setAllCompanies(companies);
      });
      await get("admin/all-users").then((response) => {
        const users: User[] = response.data.users;
        console.log(users);
        setAllUsers(users);
      });
      await get("admin/all-devices").then((response) => {
        const devices: DeviceTableType[] = response.data.devices;
        console.log(devices);
        setAllDevices(devices);
      });
    } catch {
      console.log("Failed to get admin data");
    }
  }

  const handleClickRowDevice = useCallback(
    (data: DeviceTableType) => {
      navigate("/device-admin/" + data.id);
    },
    [navigate]
  );

  const handleClickRowUser = useCallback((data: User) => {
    console.log(data);
  }, []);

  return (
    <PageLayout>
      <h3>Selskaps Admin</h3>
      <Row>
        <div style={{ width: 200 }}>
          <Button
            variant="primary"
            type="button"
            onClick={() => openCreateCompanyModal()}
          >
            Opprett selskap
          </Button>
        </div>
        <div style={{ width: 200 }}>
          <Button
            variant="primary"
            type="button"
            onClick={() => openAddUserToCompany()}
          >
            Legg til bruker
          </Button>
        </div>
      </Row>
      <div className="pt-5">
        <h3>Prosjekt og lokasjon</h3>
        <Row>
          <div style={{ width: 200 }}>
            <Button
              variant="primary"
              type="button"
              onClick={() => openCreateProjectModal()}
            >
              Opprett prosjekt
            </Button>
          </div>
          <div style={{ width: 200 }}>
            <Button
              variant="primary"
              type="button"
              onClick={() => openCreateDeviceAreaModal()}
            >
              Opprett lokasjon
            </Button>
          </div>
        </Row>
      </div>
      <div className="pt-5">
        <Row className="d-flex flex-row mb-3">
          <Col>
            <h3>Alle brukere</h3>
          </Col>
        </Row>
        <UserTable
          tableData={allUsers}
          handleClickRow={handleClickRowUser}
          isAdmin={true}
        />
      </div>
      <div className="pt-5">
        <Row className="d-flex flex-row mb-3">
          <Col>
            <h3>Alle enheter</h3>
          </Col>
          <Col>
            <Button
              variant="primary"
              type="button"
              onClick={() => openCreateDeviceModal()}
            >
              Opprett ny enhet
            </Button>
          </Col>
        </Row>
        <DeviceTable
          tableData={allDevices}
          handleClickRow={handleClickRowDevice}
          isAdmin={true}
        />
      </div>
      {showCreateCompanyModal && (
        <CreateCompanyModal
          show={showCreateCompanyModal}
          refresh={() => getAdminData()}
          onHide={() => setShowCreateCompanyModal(false)}
        />
      )}
      {showCreateProjectModal && (
        <CreateProjectModal
          show={showCreateProjectModal}
          refresh={() => getAdminData()}
          onHide={() => setShowCreateProjectModal(false)}
          companies={allCompanies}
        />
      )}
      {showCreateDeviceAreaModal && (
        <CreateAreaModal
          show={showCreateDeviceAreaModal}
          refresh={() => getAdminData()}
          onHide={() => setShowCreateDeviceAreaModal(false)}
          companies={allCompanies}
        />
      )}
      {showCreateDeviceModal && (
        <CreateDeviceModal
          show={showCreateDeviceModal}
          onHide={() => setShowCreateDeviceModal(false)}
          companies={allCompanies}
        />
      )}
      {showAddUserToCompany && (
        <AddUserToCompanyModal
          show={showAddUserToCompany}
          onHide={() => setShowAddUserToCompany(false)}
          companies={allCompanies}
          users={allUsers}
        />
      )}
    </PageLayout>
  );
};

export default AdminPage;
