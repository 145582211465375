import { Col, Row } from "react-bootstrap";
import "./LandingPage.scss";

const Vel1200Section = () => {
  return (
    <div className="mt-5">
      <div className="landing-content-container">
        <h2 className="landing-header-small">VEL 1200-LB</h2>
      </div>
      <div className="landing-content-container text-center">
        <h3 className="">RADAR DISTANSE MÅLER</h3>
        <p className="mt-3">
          Mål distanse både vertikalt og horisontalt med vår VEL 1200-LB
          distansemåler, også på steder hvor andre sensorer ofte blir unøyaktige
          på grunn av for eksempel damp og støv.
        </p>
      </div>
      <div className="mt-5">
        <Row>
          <Col>
            <h4>ENKEL Å BRUKE</h4>
            <p>
              Måleren krever ingen eksterne tilkobling av ledninger for hverken
              strøm eller data.
            </p>
          </Col>
          <Col>
            <h4>LANG BATTERITID</h4>
            <p>
              VEL 1200-LB drives av et 8500mAh Li-SOCI2 batteri, som har en
              levetid på 2-4 år.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>NØYAKTIG</h4>
            <p>
              Måleren gir svært nøyaktige målinger på ±2cm for avstander mellom
              15 til 1200cm.
            </p>
          </Col>
          <Col>
            <h4>MIKROBØLGER</h4>
            <p>
              Sensoren benytter en 60Ghz Mikrobølge radar til å måle distansen
              til ulike objekter.
            </p>
          </Col>
        </Row>
      </div>
      <div className="landing-content-container text-center mt-5">
        <h3 className="">INTRODUKSJON</h3>
        <p className="mt-3">
          Vår VEL 120-LB Radar distanse måler er en GSM IoT radar sensor som
          benytter mikrobølger til å måle distansen mellom sensor og ulike
          objekter (fra 15 til 1200cm). Denne typen sensor er en svært pålitelig
          og nøyaktig sensor også på steder hvor andre sensorer ofte blir
          unøyaktige på grunn av for eksempel damp og støv. Distanse måleren
          benyttes på svært mange ulike områder hvor det er aktuelt å måle
          avstand, både vertikalt og horisontalt, inkludert parkeringshus,
          avfallssystemer, kloakksystemer.
        </p>
      </div>
      <div className="regular-landing-content mt-5">
        <Row>
          <Col>
            <h4>EGENSKAPER</h4>
            <ul>
              <li>GSM IoT 1.0.3 klasse A</li>
              <li>Ultra-lavt strømforbruk.</li>
              <li>60Ghz Mikrobølge radar for å måle avstand.</li>
              <li>Måleområde: 15 ~ 1200cm</li>
              <li>
                Nøyaktighet:
                <br />
                ±(2cm+Avstandx0.3%). Målevinkel: 25 grader horisontalt
              </li>
              <li>Målevinkel: 25 grader horisontalt og 23 grader vertikalt</li>
            </ul>
          </Col>
          <Col>
            <h4>MIKROBØLGER</h4>
            <ul>
              <li>Horisontale distanse målinger.</li>
              <li>Målinger av veskenivå.</li>
              <li>Parkingssystemer.</li>
              <li>Måling og sjekk av tilstedeværelse til objekter.</li>
              <li>Smarte avfallssystemer.</li>
              <li>Sensor for roboter.</li>
              <li>Automatisk kontroll.</li>
              <li>Kloakk.</li>
            </ul>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Vel1200Section;
