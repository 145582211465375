import { useEffect, useState } from "react";
import { PageLayout } from "../../common/components/Layout/PageLayout/PageLayout";
import useAxios from "../../common/auth/useAxios";
import InputText from "../../common/components/InputText/InputText";
import { Button, Form, Row } from "react-bootstrap";
import { useStoreActions, useStoreState } from "../../store/store";
import { useAuth0 } from "@auth0/auth0-react";
import { set } from "date-fns";
import { Link } from "react-router-dom";
import CheckBox from "../../common/components/CheckBox/CheckBox";

const OnboardingPage = () => {
  const { user, isAuthenticated } = useAuth0();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const { post } = useAxios();
  const userAccount = useStoreState((state) => state.account.user);
  const setUserData = useStoreActions((state) => state.account.setUserData);
  const [confirmDataAgreement, setConfirmDataAgreement] =
    useState<boolean>(false);
  const [confirmDeviceAgreement, setConfirmDeviceAgreement] =
    useState<boolean>(false);

  //   const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      if (user?.given_name !== undefined) {
        setFirstName(user?.given_name);
      }
      if (user?.family_name !== undefined) {
        setLastName(user?.family_name);
      }
      if (user?.email !== undefined) {
        setEmail(user?.email);
      }
    }
  }, [isAuthenticated]);

  //   async function getDeviceData() {
  //     const response = await get("device");

  //     setName(response.data.name);
  //   }

  function handleSubmit(event: any) {
    event.preventDefault();
    event.stopPropagation();
    console.log("" + firstName + " " + lastName);
    try {
      post("user/onboard", {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        address: "",
        address2: "",
        postCode: "",
        postArea: "",
        imageUrl: "",
        otherInfo: "",
        hasApprovedDeviceAgreement: confirmDeviceAgreement,
      }).then((response) => {
        console.log(response);
        setUserData(response.data);
        window.location.reload();
      });
    } catch (error) {
      console.log(error);
    }
  }

  if (userAccount?.onboarded) {
    {
      console.log("onboarded reload");
      window.location.reload();
    }
  }

  return (
    <PageLayout>
      <div style={{ maxWidth: 300 }}>
        <h1 className="mb-5">Bruker informasjon</h1>
        <Form onSubmit={handleSubmit} style={{ maxWidth: 300 }}>
          <InputText
            value={firstName}
            onChange={(e: any) => setFirstName(e.target.value)}
            inputTitle="Fornavn"
            label={true}
          />
          <InputText
            value={lastName}
            onChange={(e: any) => setLastName(e.target.value)}
            inputTitle="Etternavn"
          />
          <InputText
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
            inputTitle="Email"
          />
          <InputText
            value={phone}
            onChange={(e: any) => setPhone(e.target.value)}
            inputTitle="Telefonnummer (valgfritt)"
          />
          <Row>
            <div className="d-flex flex-row">
              <CheckBox
                checked={confirmDeviceAgreement}
                onChange={(x: any) => {
                  setConfirmDeviceAgreement(x.target.checked);
                }}
              />
              <p className="ms-4">
                Jeg bekrefter at jeg har lest og aksepterer denne avtalen for
                <b> leie og bruk av enheter</b> fra Vastveit Elektronikk. <br />
                <Link
                  to="https://iotvelstorage.blob.core.windows.net/public/Utleie_avtale.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Åpne avtalen
                </Link>
              </p>
            </div>
          </Row>
          <Button type="submit" disabled={!confirmDeviceAgreement}>
            Lagre
          </Button>
        </Form>
      </div>
    </PageLayout>
  );
};

export default OnboardingPage;
// export default withAuthenticationRequired(Profile, {
//   onRedirecting: () => <LoadingSpinner />,
// });
