import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./DetailsSelector.scss";

type DetailsSelectorProps = {
  graphSelected: boolean;
  selectGraph: any;
};

const DetailsSelector: React.FC<DetailsSelectorProps> = ({
  graphSelected,
  selectGraph,
}) => {
  if (graphSelected) {
    return (
      <div className="d-flex flex-row">
        <div className="unit-selected me-1">
          <i
            className="fs-3 bi bi-graph-up d-flex flex-row justify-content-center align-items-center"
            style={{ color: "green", cursor: "pointer" }}
          ></i>
        </div>

        <OverlayTrigger
          overlay={<Tooltip id={"tooltip-1"}>Vis tabell</Tooltip>}
          placement="top"
          delay={100}
        >
          <div className="unit-not-selected">
            <i
              className="fs-3 bi bi-table"
              style={{ color: "gray", cursor: "pointer", zIndex: 100 }}
              onClick={() => selectGraph(false)}
            ></i>
          </div>
        </OverlayTrigger>
      </div>
    );
  }
  return (
    <div className="d-flex flex-row">
      <OverlayTrigger
        overlay={<Tooltip id={"tooltip-1"}>Vis grafer</Tooltip>}
        placement="top"
        delay={100}
      >
        <div className="unit-not-selected me-1">
          <i
            className="fs-3 bi bi-graph-up"
            style={{ color: "gray", cursor: "pointer" }}
            onClick={() => selectGraph(true)}
          ></i>
        </div>
      </OverlayTrigger>
      <div className="unit-selected">
        <i
          className="fs-3 bi bi-table"
          style={{ color: "green", cursor: "pointer" }}
        ></i>
      </div>
    </div>
  );
};

export default DetailsSelector;
