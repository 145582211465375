import React, { useRef, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/public/landing/LandingPage";
import { PageAuthenticator } from "./common/auth/PageAuthenticator";
import ProfilePage from "./pages/profile/ProfilePage";
import { CallbackPage } from "./pages/callback/CallbackPage";
import DevicePage from "./pages/device/DevicePage";
import AdminPage from "./pages/admin/AdminPage";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import ProjectsPage from "./pages/projects/ProjectsPage";
import AreasPage from "./pages/areas/AreasPage";
import DeviceAdminPage from "./pages/device-admin/DeviceAdminPage";

const AllRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />}></Route>
      <Route element={<PageAuthenticator component={Outlet} />}>
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/areas" element={<AreasPage />} />

        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/device-details/:deviceId" element={<DevicePage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/device-admin/:deviceId" element={<DeviceAdminPage />} />
      </Route>
      <Route path="/callback" element={<CallbackPage />} />

      {/* <Route
          path="/profile"
          element={<PageAuthenticator component={ProfilePage} />}
        /> */}
    </Routes>
  );
};

export default AllRoutes;

{
  /* <Route path="/" element={<LandingPage />}></Route>
      <Route
        path="/profile"
        element={<PageAuthenticator component={ProfilePage} />}
      />
      <Route
        path="/device"
        element={<PageAuthenticator component={DevicePage} />}
      />
      <Route path="/callback" element={<CallbackPage />} /> */
}
