import { Col, Row } from "react-bootstrap";

type LabelAndTextProps = {
  label?: string;
  text?: string;
};

const LabelAndText: React.FC<LabelAndTextProps> = ({
  label = "",
  text = "",
}) => {
  if (!text) return <></>;
  return (
    <Row className="my-2">
      <Col>
        <span>
          <b>{label}</b>
        </span>
      </Col>
      <Col>
        <span>{text}</span>
      </Col>
    </Row>
  );
};

export default LabelAndText;
