import { Button, Form, Modal } from "react-bootstrap";
import InputText from "../../common/components/InputText/InputText";
import { useState } from "react";
import useAxios from "../../common/auth/useAxios";
import { AdminCompany } from "./types/AdminTypes";
import Dropdown from "../../common/components/DropDown/DropDown";
import TextArea from "../../common/components/TextArea/TextArea";

type CreateAreaModalProps = {
  show: boolean;
  onHide: any;
  companies: AdminCompany[];
  refresh: any;
};

const CreateAreaModal: React.FC<CreateAreaModalProps> = ({
  show,
  onHide,
  companies,
  refresh,
}) => {
  const [deviceAreaName, setDeviceAreaName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const { post } = useAxios();

  const companyItems = companies?.map((company, i) => {
    return { label: company.name, value: company.id, id: "" + i };
  });

  function handleSubmit(event: any) {
    event.preventDefault();
    event.stopPropagation();
    console.log(deviceAreaName);
    try {
      post("device-area", {
        name: deviceAreaName,
        description,
        companyId: selectedCompanyId,
      }).then((response: any) => {
        console.log(response);
        onHide();
        refresh();
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Opprett ny lokasjon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown
            items={companyItems}
            onChange={(opt) => {
              setSelectedCompanyId(opt.value);
              setSelectedCompany(opt);
              console.log(opt.value);
            }}
            placeholder="Velg selskap"
            value={selectedCompany}
          />
          <InputText
            value={deviceAreaName}
            onChange={(e: any) => setDeviceAreaName(e.target.value)}
            inputTitle="Navn på lokasjon"
            label={true}
          />
          <TextArea
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}
            inputTitle="Beskrivelse"
            label={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Lagre</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreateAreaModal;
