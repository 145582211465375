import { Button, Form, Modal } from "react-bootstrap";
import InputText from "../../common/components/InputText/InputText";
import { useEffect, useState } from "react";
import useAxios from "../../common/auth/useAxios";
import {
  AdminCompany,
  AdminDeviceArea,
  AdminProject,
} from "../admin/types/AdminTypes";
import Dropdown from "../../common/components/DropDown/DropDown";
import TextArea from "../../common/components/TextArea/TextArea";
import { GetDeviceDetails } from "../device/types/DeviceDetails";
import { set } from "date-fns";

const transmissionTypes = [
  { label: "Ingen", value: 0, id: "0" },
  { label: "Eksternt API", value: 1, id: "1" },
  { label: "UDP", value: 2, id: "2" },
  { label: "Gemini Live", value: 3, id: "3" },
];

const deviceTypes = [
  { label: "Ikke valgt", value: 0, id: "0" },
  { label: "VEL D12", value: 1, id: "1" },
  { label: "VEL D20", value: 2, id: "2" },
  { label: "VEL D30", value: 3, id: "3" },
  { label: "VEL T1", value: 5, id: "5" },
  { label: "VEL PH1", value: 6, id: "6" },
];

type EditDeviceAdminModalProps = {
  show: boolean;
  onHide: any;
  companies: AdminCompany[];
  device?: GetDeviceDetails;
};

const EditDeviceAdminModal: React.FC<EditDeviceAdminModalProps> = ({
  show,
  onHide,
  companies,
  device,
}) => {
  const [deviceName, setDeviceName] = useState<string>("");
  const [deviceIdName, setDeviceIdName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [customerSystemName, setCustomerSystemName] = useState<string>("");
  const [transmissionAddress, setTransmissionAddress] = useState<string>("");
  const [distanceToBottom, setDistanceToBottom] = useState<number>(0);
  const [password, setPassword] = useState<string>("");
  const [iOTCardNumber, setIOTCardNumber] = useState<string>("");

  const [divisor, setDivisor] = useState<number>(1);
  const [transmissionPort, setTransmissionPort] = useState<number>(0);

  const [selectedDeviceTypeId, setSelectedDeviceTypeId] = useState<number>(0);
  const [selectedDeviceType, setSelectedDeviceType] = useState<any>();
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [selectedProjectId, setSelectedProjectId] = useState<number>(0);
  const [selectedProject, setSelectedProject] = useState<any>();
  const [selectedDeviceAreaId, setSelectedDeviceAreaId] = useState<number>(0);
  const [selectedDeviceArea, setSelectedDeviceArea] = useState<any>();
  const [selectedTransmissionTypeId, setSelectedTransmissionTypeId] =
    useState<number>(0);
  const [selectedTransmissionType, setSelectedTransmissionType] =
    useState<any>();
  const [projectItems, setProjectItems] = useState<any>([]);
  const [deviceAreaItems, setDeviceAreaItems] = useState<any>([]);
  const { post } = useAxios();

  const companyItems = companies?.map((company, i) => {
    return { label: company.name, value: company.id, id: "" + i };
  });
  useEffect(() => {
    if (selectedCompanyId !== 0) {
      const company = companies.find(
        (company) => company.id === selectedCompanyId
      );
      const projects = company?.projects;
      const projectItemsForDropdown = projects?.map((project, i) => {
        return { label: project.name, value: project.id, id: "" + i };
      });
      setProjectItems(projectItemsForDropdown);
      const deviceAreas = company?.deviceAreas;
      const deviceAreaItemsForDropdown = deviceAreas?.map((deviceArea, i) => {
        return { label: deviceArea.name, value: deviceArea.id, id: "" + i };
      });
      setDeviceAreaItems(deviceAreaItemsForDropdown);
    } else {
      console.log("device", device);
      if (device && device?.companyId) {
        console.log("device.companyId", device.companyId);
        setSelectedCompanyId(device.companyId);
        const selectedCompany = companyItems.find(
          (company) => company.value === device.companyId
        );

        setSelectedCompany(selectedCompany);

        const company = companies.find(
          (company) => company.id === device.companyId
        );
        console.log("company", company);
        const projects = company?.projects;
        const projectItemsForDropdown = projects?.map((project, i) => {
          return { label: project.name, value: project.id, id: "" + i };
        });
        setProjectItems(projectItemsForDropdown);
        const deviceAreas = company?.deviceAreas;
        const deviceAreaItemsForDropdown = deviceAreas?.map((deviceArea, i) => {
          return { label: deviceArea.name, value: deviceArea.id, id: "" + i };
        });
        setDeviceAreaItems(deviceAreaItemsForDropdown);

        if (device?.projectId && projectItemsForDropdown) {
          setSelectedProjectId(device.projectId);
          const selectedProject = projectItemsForDropdown.find(
            (project: any) => project.value === device.projectId
          );
          console.log("selectedProject", selectedProject);
          setSelectedProject(selectedProject);
        }
        if (device?.deviceAreaId && deviceAreaItemsForDropdown) {
          setSelectedDeviceAreaId(device.deviceAreaId);
          const selectedDeviceArea = deviceAreaItemsForDropdown.find(
            (deviceArea: any) => deviceArea.value === device.deviceAreaId
          );
          setSelectedDeviceArea(selectedDeviceArea);
        }
      }
    }
  }, [selectedCompanyId, companies]);

  useEffect(() => {
    if (device) {
      setDeviceName(device.name);
      setDeviceIdName(device.deviceId);
      setPassword(device.password);
      setIOTCardNumber(device.iotCardNumber);
      setDescription(device.description);
      setCustomerSystemName(device.customerSystemName);
      setDistanceToBottom(device.distanceToBottom);
      setDivisor(device.unitDivisor ?? 1);
      setTransmissionPort(device.transmissionPort ?? 0);
      setTransmissionAddress(device.transmissionAddress ?? "");
      if (device?.deviceTypeId) {
        setSelectedDeviceTypeId(device.deviceTypeId);
        setSelectedDeviceType(
          deviceTypes.find(
            (deviceTypeId) => deviceTypeId.value === device.deviceTypeId
          )
        );
      }
    }
    if (device?.transmissionType) {
      setSelectedTransmissionTypeId(device.transmissionType);
      setSelectedTransmissionType(
        transmissionTypes.find(
          (transmissionType) =>
            transmissionType.value === device.transmissionType
        )
      );
    }
  }, [device]);

  function handleSubmit(event: any) {
    event.preventDefault();
    event.stopPropagation();
    try {
      post("device/edit", {
        id: device?.id ?? 0,
        unitName: deviceName,
        name: deviceName,
        deviceId: deviceIdName,
        description,
        customerSystemName: customerSystemName,
        companyId: selectedCompanyId,
        deviceAreaId: selectedDeviceAreaId,
        projectId: selectedProjectId,
        unitDivisor: divisor,
        transmissionType: selectedTransmissionTypeId,
        transmissionAddress: transmissionAddress,
        transmissionPort: transmissionPort,
        distanceToBottom: distanceToBottom,
        password: password,
        deviceTypeId: selectedDeviceTypeId,
        iOTCardNumber: iOTCardNumber,
      }).then((response: any) => {
        console.log(response);
        onHide();
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Endre enhet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown
            items={companyItems}
            onChange={(opt) => {
              setSelectedCompanyId(opt.value);
              setSelectedCompany(opt);
              console.log(opt.value);
              setSelectedProject(null);
              setSelectedDeviceArea(null);
            }}
            placeholder="Selskap"
            value={selectedCompany}
          />
          <Dropdown
            items={projectItems}
            onChange={(opt) => {
              setSelectedProjectId(opt.value);
              setSelectedProject(opt);
              console.log(opt.value);
            }}
            placeholder="Prosjekt"
            value={selectedProject}
          />
          <Dropdown
            items={deviceAreaItems}
            onChange={(opt) => {
              setSelectedDeviceAreaId(opt.value);
              setSelectedDeviceArea(opt);
              console.log(opt.value);
            }}
            placeholder="Lokasjon"
            value={selectedDeviceArea}
          />
          <InputText
            value={deviceName}
            onChange={(e: any) => setDeviceName(e.target.value)}
            inputTitle="Navn på enhet"
            label={true}
          />
          <InputText
            value={deviceIdName}
            onChange={(e: any) => setDeviceIdName(e.target.value)}
            inputTitle="ID til enhet"
            label={true}
          />
          <Dropdown
            items={deviceTypes}
            onChange={(opt) => {
              setSelectedDeviceTypeId(opt.value);
              setSelectedDeviceType(opt);
            }}
            placeholder="Enhetstype"
            value={selectedDeviceType}
          />
          <InputText
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
            inputTitle="Passord"
            label={true}
          />
          <InputText
            value={iOTCardNumber}
            onChange={(e: any) => setIOTCardNumber(e.target.value)}
            inputTitle="IOT kortnummer"
            label={true}
          />
          <TextArea
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}
            inputTitle="Beskrivelse"
            label={true}
          />
          <InputText
            value={distanceToBottom}
            onChange={(e: any) =>
              setDistanceToBottom(parseFloat(e.target.value))
            }
            inputTitle="Avstand til bunnen"
            label={true}
            type="number"
          />

          <InputText
            value={divisor}
            onChange={(e: any) => setDivisor(parseFloat(e.target.value))}
            inputTitle="Divisor (100 = cm til m)"
            label={true}
            type="number"
          />

          <Dropdown
            items={transmissionTypes}
            onChange={(opt) => {
              setSelectedTransmissionTypeId(opt.value);
              setSelectedTransmissionType(opt);
              console.log(opt.value);
            }}
            placeholder="Overførningstype"
            value={selectedTransmissionType}
          />
          {(selectedTransmissionTypeId === 1 ||
            selectedTransmissionTypeId === 3) && (
            <InputText
              value={customerSystemName}
              onChange={(e: any) => setCustomerSystemName(e.target.value)}
              inputTitle="Enhetsnavn i mottager system"
              label={true}
            />
          )}
          {(selectedTransmissionTypeId === 1 ||
            selectedTransmissionTypeId === 2) && (
            <InputText
              value={transmissionAddress}
              onChange={(e: any) => setTransmissionAddress(e.target.value)}
              inputTitle="Mottager adresse"
              label={true}
            />
          )}
          {selectedTransmissionTypeId === 2 && (
            <InputText
              value={transmissionPort}
              onChange={(e: any) => setTransmissionPort(e.target.value)}
              inputTitle="Mottager port"
              label={true}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Lagre</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditDeviceAdminModal;
