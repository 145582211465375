import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from "@tanstack/react-table";
import { User } from "../../../pages/admin/types/AdminTypes";
import { useCallback, useMemo, useState } from "react";
import "./UserTable.scss";

type UserTableProps = {
  tableData: User[];
  isAdmin?: boolean;
  handleClickRow?: (row: User) => void;
};

const UserTable: React.FC<UserTableProps> = ({
  tableData,
  isAdmin,
  handleClickRow,
}) => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: "company", desc: false },
  ]);

  // const handleClickDelete = useCallback((id: number) => {
  //   console.log("Delete device with id: " + id);
  // }, []);

  const Regularcolumns = useMemo<ColumnDef<User>[]>(
    () => [
      {
        accessorKey: "firstName",
        cell: (info) => {
          return <i> {info.row.original.firstName}</i>;
        },
        header: () => <span>Fornavn</span>,
        enableResizing: true,
        sortingFn: "text",
      },
      {
        accessorKey: "lastName",
        cell: (info) => {
          return <i> {info.row.original.lastName}</i>;
        },
        header: () => <span>Etternavn</span>,
        enableResizing: true,
        sortingFn: "text",
      },
      {
        accessorKey: "email",
        cell: (info) => {
          return <i> {info.row.original.email}</i>;
        },
        header: () => <span>Epost</span>,
        enableResizing: false,
        sortingFn: "text",
      },
    ],
    []
  );

  const Admincolumns = useMemo<ColumnDef<User>[]>(
    () => [
      {
        accessorKey: "firstName",
        cell: (info) => {
          return <i> {info.row.original.firstName}</i>;
        },
        header: () => <span>Fornavn</span>,
        enableResizing: true,
        sortingFn: "text",
      },
      {
        accessorKey: "lastName",
        cell: (info) => {
          return <i> {info.row.original.lastName}</i>;
        },
        header: () => <span>Etternavn</span>,
        enableResizing: true,
        sortingFn: "text",
      },
      {
        accessorKey: "email",
        cell: (info) => {
          return <i> {info.row.original.email}</i>;
        },
        header: () => <span>Epost</span>,
        enableResizing: false,
        sortingFn: "text",
      },
      {
        accessorKey: "company",
        cell: (info) => {
          if (
            info.row.original.company != undefined &&
            info.row.original.company.match("Ikke knyttet til selskap")
          ) {
            return <b> {info.row.original.company}</b>;
          }
          return <i> {info.row.original.company}</i>;
        },
        header: () => <span>Selskap</span>,
        enableResizing: false,
        sortingFn: "text",
      },
    ],
    []
  );

  const table = useReactTable({
    data: tableData,
    columns: isAdmin ? Admincolumns : Regularcolumns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <div className="p-2">
      <table className="user-table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? "cursor-pointer select-none"
                          : "",
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: " 🔼",
                        desc: " 🔽",
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => {
                handleClickRow?.(row.original);
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="h-4" />
    </div>
  );
};

export default UserTable;
