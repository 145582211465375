import { Button, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import useAxios from "../../../auth/useAxios";
import CheckBox from "../../CheckBox/CheckBox";
import { Link } from "react-router-dom";
import { useStoreActions } from "../../../../store/store";

type ApproveAgreementsModalProps = {
  show: boolean;
  onHide: any;
};

const ApproveAgreementsModal: React.FC<ApproveAgreementsModalProps> = ({
  show,
  onHide,
}) => {
  const [confirmDeviceAgreement, setConfirmDeviceAgreement] =
    useState<boolean>(false);

  const setApproveDeviceAgreement = useStoreActions(
    (state) => state.account.setApproveDeviceAgreement
  );

  const { post } = useAxios();

  function handleSubmit(event: any) {
    event.preventDefault();
    event.stopPropagation();
    try {
      post("user/approve-device-agreement", {
        hasApprovedDeviceAgreement: confirmDeviceAgreement,
      }).then((response: any) => {
        setApproveDeviceAgreement(true);
        onHide();
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Bekreftelse av avtale</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="d-flex flex-row">
              <CheckBox
                checked={confirmDeviceAgreement}
                onChange={(x: any) => {
                  setConfirmDeviceAgreement(x.target.checked);
                }}
              />
              <p className="ms-4">
                Jeg bekrefter at jeg har lest og aksepterer denne avtalen for
                <b> leie og bruk av enheter</b> fra Vastveit Elektronikk. <br />
                <Link
                  to="https://iotvelstorage.blob.core.windows.net/public/Utleie_avtale.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Åpne avtalen
                </Link>
              </p>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" disabled={!confirmDeviceAgreement}>
            Lagre
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ApproveAgreementsModal;
