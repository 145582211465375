import { action } from "easy-peasy";
import { Action } from "easy-peasy";

export const account: AccountModel = {
  user: null,
  selectedCompany: null,
  companies: null,
  setUserData: action((state, payload) => {
    console.log(payload);
    state.user = payload;
    state.companies = payload.companies;
    console.log("payload.companies.length");
    if (payload.companies && payload.companies.length > 0) {
      state.selectedCompany = payload.companies[0];
      console.log(payload.companies[0]);
    }
  }),
  setApproveDeviceAgreement: action((state, payload) => {
    if (state.user) {
      state.user.hasApprovedDeviceAgreement = payload;
    }
  }),
  setSelectedCompany: action((state, payload) => {
    state.selectedCompany = payload;
  }),
};

export interface AccountModel {
  // selectors
  user: User | null;
  selectedCompany: UserCompany | null;
  companies: UserCompany[] | null;
  // actions
  setUserData: Action<this, UserWithCompanies>;
  setApproveDeviceAgreement: Action<this, boolean>;
  setSelectedCompany: Action<this, UserCompany>;
}

export type User = {
  id: number;
  email: string;
  lastName: string;
  firstName: string;
  onboarded: boolean;
  accessType: number;
  address: string;
  address2: string;
  postCode: string;
  postArea: string;
  imageUrl: string;
  otherInfo: string;
  hasApprovedDeviceAgreement: boolean;
};

export type UserWithCompanies = {
  id: number;
  email: string;
  lastName: string;
  firstName: string;
  onboarded: boolean;
  accessType: number;
  address: string;
  address2: string;
  postCode: string;
  postArea: string;
  imageUrl: string;
  otherInfo: string;
  companies: UserCompany[];
  hasApprovedDeviceAgreement: boolean;
};

export type UserCompany = {
  id: number;
  isAdmin: boolean;
  name: string;
};
