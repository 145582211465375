import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from "@tanstack/react-table";
import { DeviceTableType } from "./DeviceTableType";
import { useCallback, useMemo, useState } from "react";
import "./DeviceTable.scss";

type DeviceTableProps = {
  tableData: DeviceTableType[];
  isAdmin?: boolean;
  handleClickRow?: (row: DeviceTableType) => void;
};

const DeviceTable: React.FC<DeviceTableProps> = ({
  tableData,
  isAdmin,
  handleClickRow,
}) => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: "imeiNumber", desc: false },
  ]);

  const handleClickDelete = useCallback((id: number) => {
    console.log("Delete device with id: " + id);
  }, []);

  const Regularcolumns = useMemo<ColumnDef<DeviceTableType>[]>(
    () => [
      {
        accessorKey: "name",
        cell: (info) => {
          return <i> {info.row.original.name}</i>;
        },
        header: () => <span>Enhet</span>,
        enableResizing: true,
        sortingFn: "text",
      },
      {
        accessorKey: "customerSystemName",
        cell: (info) => {
          return <i> {info.row.original.customerSystemName}</i>;
        },
        header: () => <span>Eksternt navn</span>,
        enableResizing: true,
        sortingFn: "text",
      },
      {
        accessorKey: "projectName",
        cell: (info) => {
          return <i> {info.row.original.projectName}</i>;
        },
        header: () => <span>Prosjekt</span>,
        enableResizing: false,
        sortingFn: "text",
      },
      {
        accessorKey: "deviceAreaName",
        cell: (info) => {
          return <i> {info.row.original.deviceAreaName}</i>;
        },
        header: () => <span>Lokasjon</span>,
        enableResizing: false,
        sortingFn: "text",
      },
      {
        accessorKey: "averageDistance",
        cell: (info) => {
          return <i> {info.row.original.averageDistance}</i>;
        },
        header: () => <span>Avstand</span>,
        enableResizing: false,
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "averageSignalStrength",
        cell: (info) => {
          return <i> {info.row.original.averageSignalStrength}</i>;
        },
        header: () => <span>Signal</span>,
        enableResizing: false,
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "averageBattery",
        cell: (info) => {
          return <i> {info.row.original.averageBattery}</i>;
        },
        header: () => <span>Batteri</span>,
        enableResizing: false,
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "lastMessageReceived",
        cell: (info) => {
          var lastDate = info.row.original.lastMessageReceived;
          if (lastDate !== null && lastDate !== undefined) {
            lastDate = new Date(lastDate);
            return (
              <i>
                {" "}
                {lastDate.getHours() > 9
                  ? lastDate.getHours()
                  : "0" + lastDate.getHours()}
                :
                {lastDate.getMinutes() > 9
                  ? lastDate.getMinutes()
                  : "0" + lastDate.getMinutes()}{" "}
                -{" "}
                {lastDate.getDate() > 9
                  ? lastDate.getDate()
                  : "0" + lastDate.getDate()}
                /
                {lastDate.getMonth() + 1 > 9
                  ? lastDate.getMonth() + 1
                  : "0" + (lastDate.getMonth() + 1)}
              </i>
            );
          } else {
            return <i>Aldri mottatt</i>;
          }
        },
        header: () => <span>Siste sending</span>,
        enableResizing: false,
        sortingFn: "datetime",
      },
    ],
    []
  );

  const Admincolumns = useMemo<ColumnDef<DeviceTableType>[]>(
    () => [
      {
        accessorKey: "imeiNumber",
        cell: (info) => {
          return <i> {info.row.original.imeiNumber}</i>;
        },
        header: () => <span>IMEI</span>,
        enableResizing: true,
        sortingFn: "text",
      },
      {
        accessorKey: "password",
        cell: (info) => {
          return <i> {info.row.original.password}</i>;
        },
        header: () => <span>Passord</span>,
        enableResizing: true,
        sortingFn: "text",
      },
      {
        accessorKey: "deviceTypeName",
        cell: (info) => {
          return <i> {info.row.original.deviceTypeName}</i>;
        },
        header: () => <span>Type</span>,
        enableResizing: true,
        sortingFn: "text",
      },
      {
        accessorKey: "ownedByCompanyName",
        cell: (info) => {
          return <i> {info.row.original.ownedByCompanyName}</i>;
        },
        header: () => <span>Selskap</span>,
        enableResizing: true,
        sortingFn: "text",
      },
      {
        accessorKey: "name",
        cell: (info) => {
          return <i> {info.row.original.name}</i>;
        },
        header: () => <span>Enhet</span>,
        enableResizing: true,
        sortingFn: "text",
      },
      {
        accessorKey: "customerSystemName",
        cell: (info) => {
          return <i> {info.row.original.customerSystemName}</i>;
        },
        header: () => <span>Eksternt navn</span>,
        enableResizing: true,
        sortingFn: "text",
      },
      {
        accessorKey: "projectName",
        cell: (info) => {
          return <i> {info.row.original.projectName}</i>;
        },
        header: () => <span>Prosjekt</span>,
        enableResizing: false,
        sortingFn: "text",
      },
      {
        accessorKey: "deviceAreaName",
        cell: (info) => {
          return <i> {info.row.original.deviceAreaName}</i>;
        },
        header: () => <span>Lokasjon</span>,
        enableResizing: false,
        sortingFn: "text",
      },
      {
        accessorKey: "averageDistance",
        cell: (info) => {
          return <i> {info.row.original.averageDistance}</i>;
        },
        header: () => <span>Avstand</span>,
        enableResizing: false,
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "averageSignalStrength",
        cell: (info) => {
          return <i> {info.row.original.averageSignalStrength}</i>;
        },
        header: () => <span>Signal</span>,
        enableResizing: false,
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "averageBattery",
        cell: (info) => {
          return <i> {info.row.original.averageBattery}</i>;
        },
        header: () => <span>Batteri</span>,
        enableResizing: false,
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "lastMessageReceived",
        cell: (info) => {
          var lastDate = info.row.original.lastMessageReceived;
          if (lastDate !== null && lastDate !== undefined) {
            lastDate = new Date(lastDate);
            return (
              <i>
                {" "}
                {lastDate.getHours() > 9
                  ? lastDate.getHours()
                  : "0" + lastDate.getHours()}
                :
                {lastDate.getMinutes() > 9
                  ? lastDate.getMinutes()
                  : "0" + lastDate.getMinutes()}{" "}
                -{" "}
                {lastDate.getDate() > 9
                  ? lastDate.getDate()
                  : "0" + lastDate.getDate()}
                /
                {lastDate.getMonth() + 1 > 9
                  ? lastDate.getMonth() + 1
                  : "0" + (lastDate.getMonth() + 1)}
              </i>
            );
          } else {
            return <i>Aldri mottatt</i>;
          }
        },
        header: () => <span>Siste sending</span>,
        enableResizing: false,
        sortingFn: "datetime",
      },
      // {
      //   accessorKey: "id",
      //   cell: (info) => {
      //     return (
      //       <i
      //         className="bi bi-trash"
      //         onClick={(e) => {
      //           e.stopPropagation();
      //           e.preventDefault();
      //           handleClickDelete?.(info.row.original.id);
      //         }}
      //       />
      //     );
      //   },
      //   header: () => <span>Fjern</span>,
      //   enableResizing: false,
      //   sortingFn: "alphanumeric",
      // },
    ],
    []
  );

  const table = useReactTable({
    data: tableData,
    columns: isAdmin ? Admincolumns : Regularcolumns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <div className="p-2">
      <table className="table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? "cursor-pointer select-none"
                          : "",
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: " 🔼",
                        desc: " 🔽",
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => {
                handleClickRow?.(row.original);
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="h-4" />
    </div>
  );
};

export default DeviceTable;
