import { useAuth0 } from "@auth0/auth0-react";
import React, { useCallback, useEffect, useState } from "react";
import { PageLayout } from "../../common/components/Layout/PageLayout/PageLayout";
import useAxios from "../../common/auth/useAxios";
import { DeviceCardData } from "../../common/components/DeviceCard/DeviceCardTypes";
import DeviceCard from "../../common/components/DeviceCard/DeviceCard";
import "./DashboardPage.scss";
import { Button, Col, Row } from "react-bootstrap";
import { useStoreState } from "../../store/store";
import CreateDeviceModal from "../../common/components/modals/CreateDevice/CreateDeviceModal";
import MeasurementSelector from "../../common/components/MeasurementSelector/MeasurementSelector";
import ChartTypeSelector from "../../common/components/ChartTypeSelector/ChartTypeSelector";
import { DeviceTableType } from "../../common/components/DeviceTable/DeviceTableType";
import DeviceTable from "../../common/components/DeviceTable/DeviceTable";
import { useNavigate } from "react-router-dom";
import DetailsSelector from "../../common/components/DetailsSelector/DetailsSelector";
import UserTable from "../../common/components/UserTable/UserTable";
import { User } from "../admin/types/AdminTypes";
import ApproveAgreementsModal from "../../common/components/modals/ApproveAgreementsModal/ApproveAgreementsModal";

const DashboardPage = () => {
  const [allDevices, setAllDevices] = useState<DeviceCardData[]>([]);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [allDevicesTable, setAllDevicesTable] = useState<DeviceTableType[]>([]);
  const [showBottomUp, setShowBottomUp] = useState<boolean>(true);
  const [showLineChart, setShowLineChart] = useState<boolean>(true);
  const [showGraph, setShowGraph] = useState<boolean>(true);
  const [showCreateDeviceModal, setShowCreateDeviceModal] =
    useState<boolean>(false);
  const { get } = useAxios();
  const userData = useStoreState((state) => state.account.user);

  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  const currentSelectedCompany = useStoreState(
    (state) => state.account.selectedCompany
  );

  useEffect(() => {
    if (isAuthenticated && currentSelectedCompany != null) {
      getDashboard();
    }
  }, [isAuthenticated, currentSelectedCompany]);

  function openCreateDeviceModal() {
    setShowCreateDeviceModal(true);
  }

  const handleClickRowDevice = useCallback(
    (data: DeviceTableType) => {
      navigate("/device-details/" + data.id);
    },
    [navigate]
  );

  const handleClickRowUser = useCallback((data: User) => {
    console.log(data);
  }, []);

  async function getDashboard() {
    try {
      //replace with selected company from state or from url
      await get("dashboard/" + currentSelectedCompany?.id).then((response) => {
        if (response.data.devices && response.data.devices.length > 0) {
          //setAllDevices(response.data.devices);
          setAllDevices(response.data.devices);
          setAllUsers(response.data.users);
          console.log(response.data.devices);

          setAllDevicesTable(response.data.devices);
        }
        //   setAllCompanies(companies);
      });
    } catch {
      console.log("Failed to get admin data");
    }
  }

  return (
    <PageLayout>
      <div>
        <Row>
          <h1>Oversikt</h1>
        </Row>
        <Row>
          <Col md={7} xs={12}>
            <div className="pt-5 mb-5">
              <Row className="d-flex flex-row mb-3">
                <Col>
                  <h3>Brukere</h3>
                  <p>
                    Alle brukere registrert på {currentSelectedCompany?.name}{" "}
                  </p>
                </Col>
              </Row>
              <UserTable
                tableData={allUsers}
                handleClickRow={handleClickRowUser}
                isAdmin={false}
              />
            </div>
            <div className="d-flex flex-row">
              <div>
                <h3>Enheter</h3>
                <p>
                  Alle enheter registrert på {currentSelectedCompany?.name}{" "}
                </p>
              </div>
              {userData?.accessType && userData.accessType >= 6 && (
                <div className="d-flex ms-4">
                  <div style={{ width: 200, alignSelf: "center" }}>
                    <Button
                      variant="primary"
                      type="button"
                      onClick={() => openCreateDeviceModal()}
                    >
                      Opprett ny enhet
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col md={5} xs={12} className="d-flex mb-3">
            <div style={{ width: 200, alignSelf: "end" }}>
              <h4 className="text-center">Endre visning:</h4>
              <div className="d-flex flex-row flex-grow-1 justify-content-center mt-4">
                <DetailsSelector
                  graphSelected={showGraph}
                  selectGraph={(selectGraph: boolean) =>
                    setShowGraph(selectGraph)
                  }
                />
              </div>
              <div className="d-flex flex-row flex-grow-1 justify-content-center">
                <div className="mt-4">
                  <MeasurementSelector
                    bottomUpSelected={showBottomUp}
                    selectBottomUp={(selectBottomUp: boolean) =>
                      setShowBottomUp(selectBottomUp)
                    }
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {showGraph ? (
        <div className="mt-5 device-card-table">
          {allDevices.map((device) => {
            return (
              <div className="mb-4">
                <DeviceCard
                  cardData={device}
                  showBottomUp={showBottomUp}
                  showLineChart={showLineChart}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <DeviceTable
            tableData={allDevicesTable}
            handleClickRow={handleClickRowDevice}
          />
        </div>
      )}
      {showCreateDeviceModal && currentSelectedCompany && (
        <CreateDeviceModal
          show={showCreateDeviceModal}
          onHide={() => setShowCreateDeviceModal(false)}
          companyId={currentSelectedCompany.id.toString()}
        />
      )}
      {userData && (
        <ApproveAgreementsModal
          show={!userData?.hasApprovedDeviceAgreement}
          onHide={() => setShowCreateDeviceModal(false)}
        />
      )}
    </PageLayout>
  );
};

export default DashboardPage;
