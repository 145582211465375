import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import FormControl from "react-bootstrap/FormControl";
import { useAuth0 } from "@auth0/auth0-react";
import { useStoreActions, useStoreState } from "../../../../store/store";
import Dropdown from "../../DropDown/DropDown";
import LoginButton from "../../LoginButton/LoginButton";
import LogoutButton from "../../Logout/LogoutButton";
import { Link } from "react-router-dom";
import "./SideBar.scss";

function SidebarMenu() {
  const { user, isAuthenticated } = useAuth0();
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const userData = useStoreState((state) => state.account.user);
  const companies = useStoreState((state) => state.account.companies);
  const currentSelectedCompany = useStoreState(
    (state) => state.account.selectedCompany
  );
  const setCurrentSelectedCompany = useStoreActions(
    (state) => state.account.setSelectedCompany
  );

  const companyItems = companies?.map((company, i) => {
    return { label: company.name, value: company.id, id: "" + i };
  });

  useEffect(() => {
    if (currentSelectedCompany != null) {
      var current = companyItems?.find(
        (company) => company.value === currentSelectedCompany.id
      );
      setSelectedCompany(current);
      setSelectedCompanyId(currentSelectedCompany.id);
    }
  }, [currentSelectedCompany]);

  function setCompany(selectedId: number) {
    var selected = companies?.find((company) => company.id === selectedId);
    if (selected != null) {
      setCurrentSelectedCompany(selected);
    }
  }

  if (!isAuthenticated) {
    return null;
  }

  const name = user?.name;
  return (
    <div
      className="container-fluid"
      style={{ minWidth: 200, maxWidth: 230, position: "fixed" }}
    >
      <div className="row">
        <div className="bg-light min-vh-100 d-flex flex-column">
          <Row className="align-itemcenter">
            <div
              className="text-decoration-none d-flex justify-content-center mt-3 flex-grow-1 flex-direction-row"
              style={{ color: "blue" }}
            >
              <span className=" fs-4">
                <b>Selskap:</b>
              </span>
            </div>
            <div className="text-decoration-none d-flex justify-content-center flex-grow-1 flex-direction-row mt-3">
              {companyItems && companyItems?.length > 1 && (
                <Dropdown
                  items={companyItems}
                  onChange={(opt) => {
                    setSelectedCompanyId(opt.value);
                    setSelectedCompany(opt);
                    setCompany(opt.value);
                    console.log(opt.value);
                  }}
                  placeholder="Velg selskap"
                  value={selectedCompany}
                />
              )}
              {companyItems && companyItems?.length == 1 && (
                <span className="mt-3">
                  <b>{companyItems[0].label}</b>
                </span>
              )}
            </div>
          </Row>

          <div>
            <hr />
            <ul className="nav nav-pills flex-column navigation-section">
              {userData?.accessType === 9 && (
                <li className="nav-item  fs-4">
                  <Link
                    to="/admin"
                    className="nav-link fs-5"
                    aria-current="page"
                  >
                    <i className="fs-4 bi bi-gear"></i>
                    <span className="ms-2">Admin</span>
                  </Link>
                </li>
              )}

              <li className="nav-item fs-4">
                <Link
                  to="/dashboard"
                  className="nav-link fs-5"
                  aria-current="page"
                >
                  <i className="fs-4 bi bi-speedometer2"></i>
                  <span className="ms-2">Oversikt</span>
                </Link>
              </li>
              <li className="nav-item fs-4">
                <Link
                  to="/projects"
                  className="nav-link fs-5"
                  aria-current="page"
                >
                  <i className="fs-4 bi bi-kanban"></i>
                  <span className="ms-2">Prosjekt</span>
                </Link>
              </li>
              <li className="nav-item fs-4">
                <Link to="/areas" className="nav-link fs-5" aria-current="page">
                  <i className="fs-4 bi bi-globe"></i>
                  <span className="ms-2">Lokasjoner</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="d-flex flex-grow-1 flex-column mt-5">
            <Row className="align-item-center">
              <span className="fs-6 text-center">{name}</span>
              <div className="d-flex flex-row px-2 mt-2 justify-content-center">
                {isAuthenticated && <LogoutButton />}
              </div>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SidebarMenu;
