import { Button, Form, Modal } from "react-bootstrap";
import InputText from "../../common/components/InputText/InputText";
import { useEffect, useState } from "react";
import useAxios from "../../common/auth/useAxios";
import Dropdown from "../../common/components/DropDown/DropDown";
import TextArea from "../../common/components/TextArea/TextArea";
import { useStoreState } from "../../store/store";
import {
  DropdownDeviceArea,
  DropdownProject,
} from "../../global-types/DropDownTypes";
import { GetDeviceDetails } from "./types/DeviceDetails";

const transmissionTypes = [
  { label: "Ingen", value: 0, id: "0" },
  { label: "Eksternt API", value: 1, id: "1" },
  { label: "UDP", value: 2, id: "2" },
  { label: "Gemini Live", value: 3, id: "3" },
];

type CreateAreaModalProps = {
  show: boolean;
  onHide: any;
  projects: DropdownProject[];
  deviceAreas: DropdownDeviceArea[];
  device?: GetDeviceDetails;
};

const EditDeviceModal: React.FC<CreateAreaModalProps> = ({
  show,
  onHide,
  projects,
  deviceAreas,
  device,
}) => {
  const [deviceName, setDeviceName] = useState<string>("");
  const [deviceIdName, setDeviceIdName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [customerSystemName, setCustomerSystemName] = useState<string>("");
  // const [transmissionAddress, setTransmissionAddress] = useState<string>("");

  const [divisor, setDivisor] = useState<number>(1);
  // const [transmissionPort, setTransmissionPort] = useState<number>(0);

  const [selectedProjectId, setSelectedProjectId] = useState<number>(0);
  const [selectedProject, setSelectedProject] = useState<any>();
  const [selectedDeviceAreaId, setSelectedDeviceAreaId] = useState<number>(0);
  const [selectedDeviceArea, setSelectedDeviceArea] = useState<any>();
  // const [selectedTransmissionTypeId, setSelectedTransmissionTypeId] =
  //   useState<number>(0);
  // const [selectedTransmissionType, setSelectedTransmissionType] =
  //   useState<any>();
  const [distanceToBottom, setDistanceToBottom] = useState<number>(0);
  const [projectItems, setProjectItems] = useState<any>([]);
  const [deviceAreaItems, setDeviceAreaItems] = useState<any>([]);
  const { post } = useAxios();
  const company = useStoreState((state) => state.account.selectedCompany);

  useEffect(() => {
    const projectItemsForDropdown = projects?.map((project, i) => {
      return { label: project.name, value: project.id, id: "" + i };
    });
    setProjectItems(projectItemsForDropdown);
    const deviceAreaItemsForDropdown = deviceAreas?.map((deviceArea, i) => {
      return { label: deviceArea.name, value: deviceArea.id, id: "" + i };
    });
    setDeviceAreaItems(deviceAreaItemsForDropdown);

    if (device) {
      setDeviceName(device.name);
      setDeviceIdName(device.deviceId);
      setDescription(device.description);
      setCustomerSystemName(device.customerSystemName);
      setDivisor(device.unitDivisor || 1);
      setDistanceToBottom(device.distanceToBottom ?? 0);

      if (device?.projectId && projectItemsForDropdown) {
        setSelectedProjectId(device.projectId);
        const selectedProject = projectItemsForDropdown.find(
          (project: any) => project.value === device.projectId
        );
        console.log("selectedProject", selectedProject);
        setSelectedProject(selectedProject);
      }
      if (device?.deviceAreaId && deviceAreaItemsForDropdown) {
        setSelectedDeviceAreaId(device.deviceAreaId);
        const selectedDeviceArea = deviceAreaItemsForDropdown.find(
          (deviceArea: any) => deviceArea.value === device.deviceAreaId
        );
        setSelectedDeviceArea(selectedDeviceArea);
      }
    }
  }, [company]);

  function handleSubmit(event: any) {
    event.preventDefault();
    event.stopPropagation();
    console.log(deviceName);
    try {
      post("device/update", {
        id: device?.id,
        unitName: deviceName,
        name: deviceName,
        deviceId: deviceIdName,
        description,
        customerSystemName: customerSystemName,
        companyId: company?.id,
        deviceAreaId: selectedDeviceAreaId,
        projectId: selectedProjectId,
        unitDivisor: divisor,
        distanceToBottom: distanceToBottom,
        // transmissionType: selectedTransmissionTypeId,
        // transmissionAddress: transmissionAddress,
        // transmissionPort: transmissionPort,
      }).then((response: any) => {
        console.log(response);
        onHide();
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Opprett ny enhet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown
            items={projectItems}
            onChange={(opt) => {
              setSelectedProjectId(opt.value);
              setSelectedProject(opt);
              console.log(opt.value);
            }}
            placeholder="Prosjekt"
            value={selectedProject}
          />
          <Dropdown
            items={deviceAreaItems}
            onChange={(opt) => {
              setSelectedDeviceAreaId(opt.value);
              setSelectedDeviceArea(opt);
              console.log(opt.value);
            }}
            placeholder="Lokasjon"
            value={selectedDeviceArea}
          />
          {/* <InputText
            value={deviceName}
            onChange={(e: any) => setDeviceName(e.target.value)}
            inputTitle="Navn på enhet"
            label={true}
          /> */}
          <TextArea
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}
            inputTitle="Beskrivelse"
            label={true}
          />
          <InputText
            value={distanceToBottom}
            onChange={(e: any) =>
              setDistanceToBottom(parseFloat(e.target.value))
            }
            inputTitle="Avstand til bunnen"
            label={true}
            type="number"
          />
          <InputText
            value={customerSystemName}
            onChange={(e: any) => setCustomerSystemName(e.target.value)}
            inputTitle="Enhetsnavn i mottager system"
            label={true}
          />
          {/* <InputText
            value={divisor}
            onChange={(e: any) => setDivisor(parseFloat(e.target.value))}
            inputTitle="Divisor (1 = verdi, 10 = verdi / 10, 0.1 = verdi / 0.1 )"
            label={true}
            type="number"
          /> */}
          {/* <Dropdown
            items={transmissionTypes}
            onChange={(opt) => {
              setSelectedTransmissionTypeId(opt.value);
              setSelectedTransmissionType(opt);
              console.log(opt.value);
            }}
            placeholder="Overførningstype"
            value={selectedTransmissionType}
          />
          <InputText
            value={transmissionAddress}
            onChange={(e: any) => setTransmissionAddress(e.target.value)}
            inputTitle="Overføringsadresse"
            label={true}
          />
          <InputText
            value={transmissionPort}
            onChange={(e: any) => setTransmissionPort(parseInt(e.target.value))}
            inputTitle="Overføringsport (0 = ingen port)"
            label={true}
            type="number"
          /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Lagre</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditDeviceModal;
