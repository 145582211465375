import React from "react";
import Select from "react-select";
// import "./style.scss";

type DropdownProps = {
  items: DropdownItems[];
  placeholder: string;
  onChange: (event: any) => void;
  value: any;
};
type DropdownItems = {
  label: string;
  value: number;
  id: string;
};

const Dropdown: React.FC<DropdownProps> = ({
  items,
  placeholder,
  onChange,
  value,
}) => {
  return (
    <div className="dropdown-container mt-1 mb-3">
      <Select
        value={value}
        escapeClearsValue={false}
        options={items}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default Dropdown;
