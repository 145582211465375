import React from "react";
import Form from "react-bootstrap/Form";

type CheckBoxProps = {
  checked: boolean;
  onChange: any;
};

const CheckBox: React.FC<CheckBoxProps> = ({ checked, onChange }) => {
  return <Form.Check type="checkbox" checked={checked} onChange={onChange} />;
};

export default CheckBox;
