import React, { useEffect, useMemo, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
// import { timeFormat } from "d3-time-format";

export interface DataPoint {
  x: Date;
  y: number;
}
export interface BarDataPoint {
  [key: string]: string | number;
}

export interface LineData {
  id: string;
  data: DataPoint[];
}

export interface SmallChartProps {
  data: LineData[];
  width: number;
  height: number;
  showLineChart?: boolean;
  showAreaChart?: boolean;
  yLegend?: string;
  yMaxValue?: number;
}

const SmallChart: React.FC<SmallChartProps> = ({
  data,
  width,
  height,
  showLineChart = true,
  yLegend = "Avstand",
  yMaxValue = null,
  showAreaChart = false,
}) => {
  const [barData, setBarData] = useState<BarDataPoint[]>([]);

  useEffect(() => {
    if (data != undefined && data.length > 0 && data[0].data !== undefined) {
      const points = data[0].data.map((d: DataPoint) => {
        const date = new Date(d.x);
        console.log(date);
        return {
          timeStamp:
            "" +
            date.getDate() +
            "." +
            date.getMonth() + //+
            " (" +
            date.getHours() +
            ":" +
            date.getMinutes() +
            ")",
          height: d.y,
        };
      });
      console.log(points);
      if (!showLineChart) {
        //only show the last 6 point
        points.splice(0, points.length - 6);
      }
      setBarData(points.reverse());
    }
  }, [data, yMaxValue, showLineChart]);

  const commonProps = {
    width: width,
    height: height,
    margin: { top: 10, right: 30, bottom: 20, left: 30 },
  };

  return (
    <div style={{ width, height }}>
      {showLineChart ? (
        <ResponsiveLine
          {...commonProps}
          data={data}
          xScale={{
            type: "time",
            format: "%Y-%m-%d %H:%M",
            precision: "minute",
          }}
          xFormat="time:%H:%M"
          yScale={{
            type: "linear",
            min: 0,
            max: yMaxValue ? yMaxValue : "auto",
            stacked: false,
            reverse: false,
          }}
          yFormat=".2f"
          // curve="linear"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            format: "%m-%d %H:%M",
            tickValues: 2,
            //   orient: "bottom",
            tickSize: 4,
            tickPadding: 5,
            tickRotation: 0,
            // legend: "Time",
            // legendOffset: 15,
            // legendPosition: "middle",
          }}
          axisLeft={{
            //   orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: yLegend,
            legendOffset: -40,
            legendPosition: "middle",
          }}
          colors={showAreaChart ? "green" : "blue"}
          pointSize={5}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={showAreaChart ? "green" : "blue"}
          pointLabelYOffset={-12}
          enableArea={showAreaChart}
          useMesh={true}
          theme={{
            background: "#f0fcfc",
            axis: {
              ticks: {
                line: {
                  stroke: "#716c6c78",
                },
                text: {
                  fill: "black",
                },
              },
              legend: {
                text: {
                  fill: "black",
                },
              },
            },
            grid: {
              line: {
                stroke: "#716c6c78",
              },
            },
            tooltip: {
              container: {
                background: "rgba(255, 255, 255, 0.8)",
                color: "inherit",
              },
            },
          }}
        />
      ) : (
        <ResponsiveBar
          keys={["height"]}
          data={barData}
          colors={showAreaChart ? "green" : "blue"}
          labelTextColor={showAreaChart ? "black" : "white"}
          indexBy="timeStamp"
          margin={{ top: 50, right: 30, bottom: 80, left: 50 }}
          // indexScale={{ type: "band", round: true }}
          valueScale={{ type: "linear", max: yMaxValue ? yMaxValue : "auto" }}
          labelFormat="%m-%d"
          // axisTop={null}
          // axisRight={null}
          axisBottom={{
            //format: "%m-%d %H:%M",
            tickValues: 2,
            //   orient: "bottom",
            tickSize: 4,
            tickPadding: 5,
            tickRotation: 90,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: yLegend,
            legendOffset: -40,
            legendPosition: "middle",
          }}
          enableGridX={false}
          theme={{
            background: "#f0fcfc",
          }}
          // enableLabel={false}
          // // ... other specific Bar properties
        />
      )}
    </div>
  );
};

export default SmallChart;
