import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
// import { notify } from "common/utils/notify/notifyFunction";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { notify } from "../utils/notify/notifyFunction";

Axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const useAxios = () => {
  const { getAccessTokenSilently } = useAuth0();

  axios.interceptors.request.use(async (config: any) => {
    // if (config.url.indexOf("http") === -1) {
    //   config.url = `${process.env.REACT_APP_API_URL}/${config.url}`;
    // }

    if (typeof config.headers.Authorization === "undefined") {
      console.log("getting token");
      config.headers.Authorization = `Bearer ${await getAccessTokenSilently()}`;
    }
    return config;
  });

  axios.interceptors.response.use(
    (res) => res,
    async (err) => {
      if (axios.isCancel(err)) {
        return Promise.reject(err);
      }

      if (
        err?.config?.url === "api/users/current" &&
        err?.response?.status === 401
      ) {
        //handleLogout(authProvider);
        console.log("handleLogout");
      }

      if (err?.code === "ECONNABORTED") {
        return Promise.reject(err);
      }
      notify("Det oppsto en feil", true, "error");

      return Promise.reject(err);
    }
  );

  return {
    get: async (
      url: string,
      config?: AxiosRequestConfig<any> | undefined
    ): Promise<AxiosResponse> => axios.get(url, config),
    delete: async (
      url: string,
      config?: AxiosRequestConfig<any> | undefined
    ): Promise<AxiosResponse> => axios.delete(url, config),
    post: async (
      url: string,
      data?: any,
      config?: AxiosRequestConfig<any> | undefined
    ): Promise<AxiosResponse> => axios.post(url, data, config),
    put: async (
      url: string,
      data?: any,
      config?: AxiosRequestConfig<any> | undefined
    ): Promise<AxiosResponse> => axios.put(url, data, config),
    patch: async (
      url: string,
      data?: any,
      config?: AxiosRequestConfig<any> | undefined
    ): Promise<AxiosResponse> => axios.patch(url, data, config),
  };
};

export default useAxios;
